import React from "react";
import { Container, Typography } from "@mui/material";

export default function AboutConference() {
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            color: "#1b4e9b",
            textDecoration: "underline 5px #bccf17",
            textUnderlineOffset: "15px",
            "@media (max-width: 750px)": {
              fontSize: "22px",
              "@media (max-width: 450px)": {
                fontSize: "18px",
                lineHeight: "50px",
              },
            },
          }}
        >
          ABOUT CONFERENCE
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#2d2d2d",
            mt: "40px",
            textAlign: "justify",
            "@media (max-width: 900px)": {
              fontSize: "14px",
              "@media (max-width: 450px)": {
                mt: "20px",
              },
            },
          }} className="text"
        >
          The POMS India International Conference-2023 serves as an ideal
          platform for academicians and practitioners worldwide to unite,
          exchange ideas, and explore innovative approaches to redefine
          Production and Operations Management as a field. The conference aims
          at fostering collaboration to discover sustainable solutions that can
          make a meaningful impact. The theme of this year’s conference is
          “Envisioning the Future of Operations for Sustainability”.
          Sustainability in operations management is an increasingly important
          topic for businesses and the conference theme provides a timely
          platform for scholars to share their latest studies in this domain.
          This conference marks an important stride toward closer collaboration
          among scholars across borders in the POM community to equip ourselves
          to achieve sustainable development goals.
          <br />
          <br />
          <br />
          The primary aim of the conference is to provide a platform for
          academicians, researchers, and practitioners worldwide to:
          <br />
          <ul>
            <li>
              Disseminate research, offer valuable insights into the challenges
              and opportunities, and emphasize emerging strategies and
              analytical tools within the field of Production and Operations
              Management.
            </li>
            <li>
              Share ideas and approaches to achieve carbon neutrality within the
              supply chain, highlighting sustainable practices and initiatives.
            </li>
            <li>
              Evaluate pathways to enhance Environmental, Social, and Governance
              (ESG) in Supply Chain Management, thereby promoting responsible
              and ethical business operations.
            </li>
            <li>
              Exchange innovative ideas on the Production and Operations
              Management domain with a focus on navigating and developing
              successful business models in the wake of supply chain crises.
            </li>
            <li>
              Showcase case studies demonstrating the applications of
              cutting-edge tools and technologies in real-world scenarios
              highlighting their effect on Production and Operations Management
              practices.
            </li>
          </ul>
          <br />
          For further details about the conference please visit{" "}
          <a
            style={{ textDecoration: "none", color: "#2d2d2d" }}
            href="https://poms-2023.xlri.ac.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://poms-2023.xlri.ac.in
          </a>
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            color: "#6d6d6d",
            mt:"50px"
            // textDecoration: "underline 5px #bccf17",
            // textUnderlineOffset: "15px",
          }}
        >
          Hybrid mode*
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "#2d2d2d", mt:"10px","@media (max-width: 900px)": {
               fontSize:"14px"
              }}}>Participants are encouraged to attend the conference offline at XLRI Jamshedpur</Typography> */}
      </Container>
    </>
  );
}
