import { useState } from "react";
import { BrowserRouter as Router, Routes, Route , Navigate} from "react-router-dom";

import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Homepage from "./screens/homepage";
import GuestSpeakers from "./screens/guestSpeakers";
import Login from "./screens/login";
import ForgetPassword from "./screens/forgotPassword";
import UpdatePassword from "./screens/updatePassword";
import Signup from "./screens/signup";
import Submission from "./screens/submission";
import Thankyou from "./screens/thankyou";
import EditSubmission from "./screens/editSubmission";
import Admin from "./screens/admin";
import PaperDetails from "./screens/paperDetails";
import CheckoutPage from "./screens/checkoutPage";
import PaymentStatus from "./screens/paymentStatus";
import Accomodation from "./screens/accomodation";
import DaySchedule from "./screens/daySchedule";
import AccomodationDashboard from "./screens/accomodationDashboard";

function App() {

  const [user, setUser] = useState(false)

  return (
     <Router>
     <Navbar/>
        <Routes>
          <Route
          path="/"
            element={<Homepage/> }
          />
          <Route
          path="/guests"
            element={<GuestSpeakers/> }
          />
          <Route
          path="/login"
          element={user ? <Navigate to={"/submission"} replace={true} /> : <Login setUser={setUser} />}
          />
          <Route
          path="/register"
          element={user ? <Navigate to={"/submission"} replace={true} /> : <Signup setUser={setUser} />}
          />
          <Route
          path="/submission"
          element={user ? <Submission user={user} setUser={setUser} /> : <Navigate to={"/login"} replace={true} />}
          />
          <Route
          path="/thank-you"
            element={<Thankyou user={user} setUser={setUser}/>}
          />
          <Route
          path="/forgot-password"
            element={<ForgetPassword/>}
          />
          <Route
          path="/password/reset/:token"
            element={<UpdatePassword/>}
          />
          <Route
          path="/edit/:id"
            element={<EditSubmission user={user} setUser={setUser}/>}
          />
          <Route
          path="/somewhere/in/www/admin/"
            element={<Admin/>}
          />
          <Route
          path="/somewhere/in/www/admin/:limit"
            element={<Admin/>}
          />
          <Route
          path="/paper-details/:id"
            element={<PaperDetails/>}
          />
           {/* <Route
          path="/checkout/:id"
            element={<CheckoutPage/>}
          /> */}
           <Route
          path="/somewhere/in/www/payments/"
            element={<PaymentStatus/>}
          />
           <Route
          path="/somewhere/in/www/accomodation"
            element={<AccomodationDashboard/>}
          />
          <Route
          path="/accomodation"
            element={<Accomodation/>}
          />
          <Route
          path="/schedule"
            element={<DaySchedule/>}
          />
        </Routes>
        <Footer/>
    </Router>
  );
}

export default App;
