import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Paper,
  TextField,
  Snackbar,
  Button,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ForgetPassword() {

  const [email, setEmail] = useState("")

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  console.log("email "+email)

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
        if(!email){
            alert("Invalid Details")
            return
        }
        const response = await axios.post("/api/v1/forgot/password",{
            email
        });
        setOpen(response?.data?.message)
        setEmail("")
    } catch (error) {
        setOpen(error?.response?.data?.message)
    }
  };

  document.onkeydown = checkKey;

  function checkKey(e) {

    e = e || window.event;

    if (e.keyCode === 13) {
      submitHandler(e);
    }

  }

  return (
    <>
    <Snackbar
        open={open?true:false}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={open==="User not found"?"error":"success"} sx={{ width: "100%" }}>
          {open}
        </Alert>
      </Snackbar>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height:"calc(100vh - 0px)",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "10px",
            color: "#2d2d2d",
          }}
        >
          Forgot Password
        </Typography>
        <Paper
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "400px",
            "@media (max-width: 550px)": {
              minWidth: "90%",
            },
          }}
        >
          <TextField
            id="email"
            type="text"
            label="Enter your  Email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mt: 2,
              width: "100%",
            }}
          />

          <Button
            variant="contained"
            sx={{
              width: "100%",
              my: "20px",
            }}
            onClick={submitHandler}
          >
            Submit
          </Button>
        </Paper>
      </Container>
    </>
  );
}
