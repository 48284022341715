import { Box, Typography, Stack, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

export default function Main() {

  let navigate  = useNavigate()
  return (
    <>
      <Box
      id="home"
        sx={{
          mt: "60px",
          background:
            "linear-gradient(0deg,rgba(0 0 0 / 80%),rgba(27 78 155 / 70%)), url('https://xlri.ac.in/images/slider/banner3_new.jpg')",
          minHeight: "calc(100vh - 60px)",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
                "@media (max-width: 600px)": {
                  mt:"57px"
                 }
        }}
      >
        <Zoom>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "calc(100vh - 60px)",
          }}
        >
            <Box sx={{ width: "100%", textAlign: "center" }}>
            <img
                style={{ width: "550px", height: "auto", maxWidth: "100%" }}
                src={require("../../../components/assets/xlri-poms-2023.png")}
              alt=""
            />
              {/* <img
              style={{ width: "45%", height: "auto" }}
              src={require("../../../components/assets/poms-logo.png")}
              alt=""
            /> */}
          </Box>
          <Typography sx={{ fontSize: "28px", color: "white",mb:"30px",  "@media (max-width: 900px)": {
                fontSize: "22px",
                "@media (max-width: 550px)": {
                  fontSize: "18px",
                },
              }, }}>
            POMS India International Conference 2023
          </Typography>
          {/* <Typography sx={{ fontSize: "28px", color: "white", mb: "30px",  "@media (max-width: 900px)": {
                fontSize: "22px",
                "@media (max-width: 550px)": {
                  fontSize: "18px",
                },
              }, }}>
            (Hybrid Mode)
          </Typography> */}
          <Typography
            sx={{ fontSize: "24px", color: "white", fontWeight: "600",  "@media (max-width: 900px)": {
              fontSize: "20px",
              "@media (max-width: 550px)": {
                fontSize: "16px",
              },
            }, }}
          >
            December 04-06, 2023
          </Typography>
          <Typography
            sx={{ fontSize: "20px", color: "white", fontWeight: "600",  "@media (max-width: 900px)": {
              fontSize: "16px",
              "@media (max-width: 550px)": {
                fontSize: "12px",
                width:"80%",
                mt:2
              },
            }, }}
            textAlign={"center"}
          >
            Envisioning the Future of Operations for Sustainability
          </Typography>
          <Typography sx={{ fontSize: "18px", color: "#bccf17", my: "20px","@media (max-width: 550px)": {
                  fontSize: "16px",
                }, }}>
            "eProceedings available in session login"
          </Typography>
          <Box sx={{ display:"flex", justifyContent:"center", alignItems:"center", gap:"20px","@media (max-width: 900px)": {
              flexDirection:"column", gap:"10px"
            }, }}>
            <Button
            
              color="success"
              sx={{
                color: "white",
                px:2,
                fontSize:"14px",
                "@media (max-width: 550px)": {
                  fontSize: "12px",
                },
                border: "2px solid #bccf17",
                borderRadius: "20px",
                "&:hover": { background: "#1b4e9b",border: "2px solid #1b4e9b" },
              }}
            >
              Session Login
            </Button>
            <Button
              onClick={()=>navigate('/schedule')}
              color="success"
              sx={{
                color: "white",
                px:2,
                fontSize:"14px",
                "@media (max-width: 550px)": {
                  fontSize: "12px",
                },
                border: "2px solid #bccf17",
                borderRadius: "20px",
                "&:hover": { background: "#1b4e9b",border: "2px solid #1b4e9b" },
              }}
            >
              Programme Schedule
            </Button>
            <Button
            
              color="success"
              sx={{
                color: "white",
                px:2,
                fontSize:"14px",
                "@media (max-width: 550px)": {
                  fontSize: "12px",
                },
                border: "2px solid #bccf17",
                borderRadius: "20px",
                "&:hover": { background: "#1b4e9b",border: "2px solid #1b4e9b" },
              }}
            >
              Best Paper Award Result
            </Button>
            <Button
              onClick={()=>navigate("/guests")}
              color="success"
              sx={{
                color: "white",
                px:2,
                fontSize:"14px",
                "@media (max-width: 550px)": {
                  fontSize: "12px",
                },
                border: "2px solid #bccf17",
                borderRadius: "20px",
                "&:hover": { background: "#1b4e9b",border: "2px solid #1b4e9b" },
              }}
            >
              Guest & Speakers
            </Button>
          </Box>
        </Box>
        </Zoom>
      </Box>
      <Fade>
      {/* <Container maxWidth="xl" sx={{ pt: "100px", display:"flex", justifyContent:"space-between","@media (max-width: 450px)": {
                 pt:"40px"
                } }} id="about-conference">
            <img style={{width:"18%", borderRadius:"30px 0px"}} src={require("../../../components/assets/technology.jpg")} alt="" />
            <img style={{width:"18%", borderRadius:"30px 0px"}} src={require("../../../components/assets/technology.jpg")} alt="" />
            <img style={{width:"18%", borderRadius:"30px 0px"}} src={require("../../../components/assets/technology.jpg")} alt="" />
            <img style={{width:"18%", borderRadius:"30px 0px"}} src={require("../../../components/assets/technology.jpg")} alt="" />
            <img style={{width:"18%", borderRadius:"30px 0px"}} src={require("../../../components/assets/technology.jpg")} alt="" />
      </Container> */}
      </Fade>
    </>
  );
}
