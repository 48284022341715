import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Typography,
  Box,
  Paper,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifyUser from "../../components/verifyUser";

export default function Accomodation() {

    let navigate = useNavigate();

    const [open, setOpen] = useState(true)
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState(2)
    const [subCategory, setSubCategory] = useState(1)
    const [formId, setFormId] = useState("FORM-DYN-2023-RA4KYM")

    useEffect(() => {
        if(category === 2){
          if(subCategory === "1"){
            setFormId("FORM-DYN-2023-RA4KYM")
        }
        else if(subCategory === "2"){
            setFormId("FORM-DYN-2023-DIEZCX")
        }
        else {
            setFormId("FORM-DYN-2023-32OUG6")
        }
            
        }
        else{
          setFormId("FORM-DYN-2023-TRONHQ")
        }
    }, [category,subCategory])
    
    useEffect(() => {
      setLoading(true)
    }, [formId])
    
  return (
    <>
      {/* <VerifyUser open={open} setOpen={setOpen}/> */}
     <Container
        maxWidth="lg"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mt: "20px" }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "25px",
            fontWeight: "600",
            my: "50px",
            color: "#6d6d6d",
          }}
        >
          Accomodation Form
        </Typography>

        <Paper
          sx={{
            p: 4,
            mt: "20px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
             <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Categories</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="Programs"
                  onChange={(e) =>
                    setCategory(e.target.value)
                  }
                >
                  <MenuItem value={2}>Paper Presenters</MenuItem>
                  <MenuItem value={3}>Mere Participants (Conferences, Jubilee Events, etc)</MenuItem>
                </Select>
              </FormControl>
         
         {
            category===2?
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Sub Categories
                </FormLabel>
                <RadioGroup
                  
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Corporate Representatives"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Academicians (Professors/faculty)"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Research Scholars/Students:"
                  />
                </RadioGroup>
              </FormControl>:""}
              
          <Box
            sx={{
              position:"relative"
            }}
          >
            {loading  ? (
            <CircularProgress sx={{position:"absolute", left:0, right:0, top:"100px", margin:"0 auto"}}/>
        ) :""}
              <iframe
              src={`https://register.xlri.ac.in/public-form?formId=${formId}`}
              width="100%"
              height="750px"
              title="XLRI Website"
              frameborder="0"
              border="0"
              onLoad={()=>setLoading(false)}
            />
        </Box>
        </Paper>
      </Container>
    </>
  )
}
