import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Moment from "react-moment";

export default function Thankyou({ user, setUser }) {

    let navigate = useNavigate()

    const [paymentStatus, setPaymentStatus] = useState([])

    const fetchPayment = async (e) => {
      try {
        const response = await axios.get("/api/v1/payment/allIndianPayments");
        setPaymentStatus(response.data?.payments);
      } catch (error) {
        console.log(error);
      }
    };

  const logoutHandler = async (e) => {
    try {
      const response = await axios.get("/api/v1/logout");
      setUser(false);
      navigate("/")
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const fetchUser = async (e) => {
    try {
        const response = await axios.get("/api/v1/my-paper");
        setUser(response.data?.user)
    } catch (error) {
        console.log(error?.response?.data?.message)
    }
  };

  useEffect(() => {
    fetchPayment()
    fetchUser()
  }, [])

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mt: "20px" }}
        >
          <Typography sx={{ fontSize: "20px", color: "#2d2d2d" }}>
            Hi, {user?.name}
          </Typography>
          <Button variant="contained" onClick={logoutHandler}>
            Logout
          </Button>
          
        </Box>
        <Typography sx={{ fontSize: "16px", color: "#2d2d2d",mt:1 }}>
            <b>Application No.</b> {user?.appNo}
          </Typography>

          {paymentStatus?.filter((e) => {
          if (e?.email?.toLowerCase() === user?.email?.toLowerCase()) return e;
        }).length === 0 ? (
          <Typography sx={{ fontSize: "16px", color: "#2d2d2d", mt: 1 }}>
            <b>Payment Status:</b>{" "}
            <span sx={{ textTransform: "uppercase" }}>Pending</span>
          </Typography>
        ) : (
          ""
        )}
        {paymentStatus
          ?.filter((e) => {
            if (e?.email?.toLowerCase() === user?.email?.toLowerCase()) return e;
          })
          ?.map((x) => {
            return (
              <>
                <Typography sx={{ fontSize: "16px", color: "#2d2d2d", mt: 1 }}>
                  <b>Payment Status:</b>{" "}
                  <span sx={{ textTransform: "uppercase" }}>{x?.status}</span>
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#2d2d2d", mt: 1 }}>
                  <b>Payment Amount:</b>{" "}
                  <span sx={{ textTransform: "uppercase" }}>{x?.amount}/-</span>
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#2d2d2d", mt: 1 }}>
                  <b>Payment Date:</b>{" "}
                  <span sx={{ textTransform: "uppercase" }}>
                    <Moment format="DD-MM-YYYY">{x?.createdAt}</Moment>
                  </span>
                </Typography>
              </>
            );
          })}
        {/* <Typography textAlign={"center"} sx={{fontSize:"24px", fontWeight:"600", my:"40px", color:"#2d2d2d"}}>ABSTRACT SUBMISSION</Typography> */}
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "30px",
            fontWeight: "600",
            my: "50px",
            color: "#8d8d8d",
          }}
        >
          Your Submission
        </Typography>
        {user?.papers?.map((e, i) => {
          return (
            <>
              <Paper
                sx={{
                  p: 4,
                  mt: "20px",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
                  "@media (max-width: 1000px)": {
                   p:2
                  },
                }}
              >
                <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                  <Button onClick={() => navigate(`/edit/${e?._id}`)} variant="contained" startIcon={<EditIcon />}>Edit</Button>
                </Box>
                {/* <Typography textAlign={"center"} sx={{fontSize:"24px", fontWeight:"600",color:"#4d4d4d", mb:"10px"}}>Paper {i+1}</Typography> */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
                >
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Title : </b>
                    {e?.title}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Abstract : </b> {e?.abstract}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Keywords : </b> {e?.keywords}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Uploaded File : </b> {e?.fileUrl?<a style={{textDecoration:"none"}} href={e?.fileUrl} target="_blank" rel="noopener noreferrer"><Button variant="text" startIcon={<PictureAsPdfIcon />}>View</Button></a>:"No file uploaded"}
                  </Typography>
                </Box>
                
                <Typography
                  textAlign={"center"}
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "#4d4d4d",
                    mt: "30px",
                  }}
                >
                  Authors
                </Typography>
                <Box sx={{ display:"grid", alignItems:"stretched", gridTemplateColumns: "repeat(3,auto)", mt:"20px", gap:"20px", "@media (max-width: 1000px)": {
                              gridTemplateColumns: "repeat(1,100%)",
                              mt:"0px"
                            }, }}>
                  {e?.authors?.map((a) => {
                    return (
                      <>{
                        a?.firstName?
                      
                        <Paper
                          sx={{
                            p: 4,
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
                            width: "80%",
                          }}
                        >
                          <Typography>
                            <b>Name :</b>
                            {a?.firstName} {a?.lastName}
                          </Typography>
                          <Typography>
                            <b>Email :</b>
                            {a?.email}
                          </Typography>
                          <Typography>
                            <b>Region :</b>
                            {a?.region}
                          </Typography>
                          <Typography>
                            <b>Affiliation :</b>
                            {a?.affiliation}
                          </Typography>
                          <Typography>
                            <b>Webpage :</b>
                            {a?.webPage}
                          </Typography>
                          <Typography>
                          {a?.isCorrespondingAuthor?
                            <b>Corresponding author</b>:""
                            }
                          </Typography>
                        </Paper>:""}
                      </>
                    );
                  })}
                </Box>
              </Paper>
            </>
          );
        })}
      </Container>
    </>
  );
}
