import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { exportToExcel } from "react-json-to-excel";
import Moment from "react-moment";
import {
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Button,
  IconButton,
  Tabs,
  Tab,
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginDialogBox from "../../components/loginDialogBox";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AccomodationDashboard() {
  let navigate = useNavigate();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "40px",
            color: "#2d2d2d",
          }}
        >
          ACCOMODATION DETAILS
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab
                  label="Academicians (Professors/faculty)"
                  {...a11yProps(0)}
                />
                <Tab label="Research Scholars/Students" {...a11yProps(1)} />
                <Tab label="Mere Participants" {...a11yProps(2)} />
                <Tab label="Corporate Representatives" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AccomodationTable
              formId={"FORM-DYN-2023-DIEZCX"}
              title={"Academicians (Professors/faculty)"}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AccomodationTable
              formId={"FORM-DYN-2023-32OUG6"}
              title={"Research Scholars/Students"}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AccomodationTable
              formId={"FORM-DYN-2023-TRONHQ"}
              title={"Mere Participants"}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <AccomodationTable
              formId={"FORM-DYN-2023-RA4KYM"}
              title={"Corporate Representatives"}
            />
          </CustomTabPanel>
        </Box>
      </Container>
    </>
  );
}

function AccomodationTable({ formId, title }) {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [exportLoading, setExportLoading] = useState(false);

  const fetchSchedule = async (e) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/v1/user/accomodation/${formId}`);
      setData(response.data?.csvData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportData = async () => {
    setExportLoading(true);
    try {
      exportToExcel(data, title);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedule();
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer sx={styles.table} component={Paper}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "20px" }}>
            <Button
              disabled={exportLoading}
              onClick={exportData}
              endIcon={<OpenInNewIcon />}
            >
              Export Data
            </Button>
          </Box>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead sx={{ background: "#D3D3D3" }}>
              <TableRow>
                <TableCell sx={styles.headTxt}>S.No.</TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Name
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Email
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Phone No.
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Gender
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Arrival Time
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Departure Time
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Amount
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Payment Status
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Payment Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={styles.bodyTxt} component="th" scope="row">
                      {i + 1}.
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.Name}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.Email}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.["Mobile Number"]}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.Gender}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.["Arrival Time"]} {row?.["Arrival Date"]}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.["Arrival Time"]} {row?.["Arrival Date"]}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.amount ? row?.amount : "-"}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.paymentStatus}
                    </TableCell>
                    <TableCell sx={styles.bodyTxt} align="left">
                      {row?.paymentDate ? (
                        <Moment format="DD-MM-YYYY">{row?.paymentDate}</Moment>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

const styles = {
  headTxt: {
    fontSize: "16px",
    fontWeight: "600",
  },
  bodyTxt: {
    fontSize: "15px",
    wordWrap: "break-word",
    maxWidth: "200px",
  },
  txt1: {
    fontWeight: "600",
    minWidth: "120px",
  },
  txt2: {
    wordWrap: "break-word",
    maxWidth: "180px",
  },
};
