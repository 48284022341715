import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Snackbar,
  Button,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UpdatePassword() {

const params = useParams();
let navigate = useNavigate()

  const [password, setPassword] = useState("")
  const [cPassword, setCPassword] = useState("")

  const [open, setOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    navigate("/login")
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
        if(!password){
            alert("Invalid Details")
            return
        }
        else if(password !== cPassword){
            alert("Confirm Password does not match")
            return
        }
        const response = await axios.put(`/api/v1/password/reset/${params.token}`,{
            password
        });
        setOpen(response?.data?.message)
    } catch (error) {
        setOpen(error?.response?.data?.message)
    }
  };

  document.onkeydown = checkKey;

  function checkKey(e) {

    e = e || window.event;

    if (e.keyCode === 13) {
      submitHandler(e);
    }

  }

  return (
    <>
    <Snackbar
        open={open?true:false}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={open==="Password Updated"?"success":"error"} sx={{ width: "100%" }}>
          {open}
        </Alert>
      </Snackbar>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height:"calc(100vh - 0px)",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "10px",
            color: "#2d2d2d",
          }}
        >
          
          Update Password
        </Typography>
        <Paper
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "400px",
            "@media (max-width: 550px)": {
              minWidth: "90%",
            },
          }}
        >
           <FormControl
            variant="outlined"
            sx={{
              mt: 2,
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="password"
              placeholder="*****"
              type={showPassword ? "text" : "password"}
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{
              mt: 2,
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="password"
              placeholder="*****"
              type={showCPassword ? "text" : "password"}
              value={cPassword || ""}
              onChange={(e) => setCPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={()=>setShowCPassword(!showCPassword)}
                    edge="end"
                  >
                    {showCPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>

          <Button
            variant="contained"
            sx={{
              width: "100%",
              my: "20px",
            }}
            onClick={submitHandler}
          >
            Update
          </Button>
        </Paper>
      </Container>
    </>
  );
}
