import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import {
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Button,
  IconButton,
  Tabs,
  Tab,
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginDialogBox from "../../components/loginDialogBox";
import VerifyPaymentDialogBox from "../../components/verifyPaymentDialogBox";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PaymentStatus() {
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false)

  const updatePayment = async (e) => {
    try {
      setIsLoading(true)
      const response = await axios.get("/api/v1/payment/updateStatus");
      alert(response.data?.message)
    } catch (error) {
      console.log(error);
    } finally{
      setIsLoading(false)
    }
  };

  const checkAuth = (e) => {
    if (localStorage.getItem("adminAuth") !== "authenticate") {
      setOpen(true);
    }
  };

  const logoutHandler = (e) => {
    localStorage.setItem("adminAuth", "");
    setOpen(true);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    checkAuth();
  }, []);
  

  return (
    <>
      <LoginDialogBox open={open} setOpen={setOpen} />
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "20px",
            "@media (max-width: 1405px)": {
              mt: "30px",
            },
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Stack spacing={2} direction="row">
            <Button
            onClick={updatePayment}
            endIcon={isLoading?<CircularProgress sx={{color:"white"}} />:""}
            disabled={isLoading}
            loadingPosition="end"
            variant="contained"
          >
            update
          </Button>
          <Button variant="contained" onClick={logoutHandler}>
            Logout
          </Button>
          </Stack>
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "40px",
            color: "#2d2d2d",
          }}
        >
          PAYMENT STATUS
        </Typography>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                  >
                    <Tab label="INDIAN" {...a11yProps(0)} />
                    <Tab label="INTERNATIONAL" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <PaymentTable/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <IntPaymentTable/>
              </CustomTabPanel>
            </Box>
      </Container>
    </>
  );
}

function PaymentTable() {


  const paidFor = (amount) => {
    if (amount === "10030") {
      return "Academicians";
    } else if (amount === "3540") {
      return "Research Scholars";
    } else if (amount === "11800") {
      return "Industry Professionals";
    } else if (amount === "24780") {
      return "Academicians";
    } else if (amount === "14160") {
      return "Research Scholars";
    } else {
      return "-";
    }
  };

  const [indPayment, setIndPayment] = useState([]);

  const [isLoading, setIsLoading] = useState(false)

  const fetchPayment = async (e) => {
    try {
      setIsLoading(true)
      const response = await axios.get("/api/v1/payment/allIndianPayments");
      setIndPayment(response.data?.payments);
    } catch (error) {
      console.log(error);
    } finally{
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchPayment()
  }, []);

  return (
    <>
    {isLoading  ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height:"100vh"
            }}
          >
            <CircularProgress />
          </Box>
        ) :
      <TableContainer sx={styles.table} component={Paper}>
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          <TableHead sx={{ background: "#D3D3D3" }}>
            <TableRow>
              <TableCell sx={styles.headTxt}>S.No.</TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Name
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Email
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Payment Status
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Created At
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Amount
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Paid For
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indPayment?.map((row, i) => (
              <TableRow
                key={row?._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={styles.bodyTxt} component="th" scope="row">
                  {i + 1}.
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.name}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.email}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.status}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  <Moment format="DD-MM-YYYY">{row?.createdAt}</Moment>
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.amount}/-
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {paidFor(row?.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
}
    </>
  );
}

function IntPaymentTable() {

  const [open, setOpen] = useState(false)

  const [intPayment, setIntPayment] = useState([]);

  const [isLoading, setIsLoading] = useState(false)

  const fetchPayment = async (e) => {
    try {
      setIsLoading(true)
      const response = await axios.get("/api/v1/payment/allInternationalPayments");
      setIntPayment(response.data?.payments);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchPayment()
  }, [open]);

  return (
    <>
    <VerifyPaymentDialogBox open={open} setOpen={setOpen}/>
    {isLoading  ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height:"100vh"
            }}
          >
            <CircularProgress />
          </Box>
        ) :
      <TableContainer sx={styles.table} component={Paper}>
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          <TableHead sx={{ background: "#D3D3D3" }}>
            <TableRow>
              <TableCell sx={styles.headTxt}>S.No.</TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Reg. Id.
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Name
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Order Id.
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Email
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Payment Status
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Created At
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Amount
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Paid For
              </TableCell>
              <TableCell sx={styles.headTxt} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {intPayment?.map((row, i) => (
              <TableRow
                key={row?._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={styles.bodyTxt} component="th" scope="row">
                  {i + 1}.
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.RegId}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.name}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.orderId}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.email}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.status}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  <Moment format="DD-MM-YYYY">{row?.createdAt}</Moment>
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {row?.amount}/-
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                 {row?.paymentFor}
                </TableCell>
                <TableCell sx={styles.bodyTxt} align="right">
                  {
                    row?.status === "pending"?
                    <Button variant="contained" color="success" onClick={()=>setOpen(row._id)}>Verify</Button>:
                    <Button variant="contained" onClick={()=>setOpen(row._id)}>Edit</Button>
                  }
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
}
    </>
  );
}


const styles = {
  headTxt: {
    fontSize: "16px",
    fontWeight: "600",
  },
  bodyTxt: {
    fontSize: "15px",
    wordWrap: "break-word",
    maxWidth: "200px",
  },
  txt1: {
    fontWeight: "600",
    minWidth: "120px",
  },
  txt2: {
    wordWrap: "break-word",
    maxWidth: "180px",
  },
};
