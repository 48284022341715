import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
  IconButton,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function VerifyPaymentDialogBox({ open, setOpen }) {
  let today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const formattedToday = yyyy + "-" + mm + "-" + dd;

  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [data, setData] = useState({
    transactionId: "",
    settlementDate: formattedToday,
    amount: "",
  });

  const handleClose = () => {
    setOpen(false);
    setData({ transactionId: "", settlementDate: formattedToday, amount: "" });
  };

  const handleChange = (key) => {
    key.preventDefault();
    setData({ ...data, [key.target.id]: key.target.value });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await axios.put(`/api/v1/payment/verify/${open}`, {
        transactionId: data.transactionId,
        settlementDate: data.settlementDate,
        amount: data.amount.toString(),
      });
      alert(response.data?.message);
      handleClose();
    } catch (error) {
      console.log(error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPayment = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/v1/payment/status/${open}`);
      setPaymentData(response.data?.payment);
      setData({
        amount: parseInt(response.data?.payment?.amount) || "",
        settlementDate:
          response.data?.payment?.settlementDate?.substring(0, 10) ||
          formattedToday,
        transactionId: response.data?.payment?.transactionId || "",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);

  useEffect(() => {
    fetchPayment();
  }, [open]);

  return (
    <>
      <Dialog
        sx={{ backdropFilter: "blur(10px)" }}
        open={open}
        onClose={handleClose}
      >
        <IconButton
          sx={{ position: "absolute", top: "0px", right: "0px" }}
          aria-label="delete"
          onClick={handleClose}
          size="large"
        >
          <CloseIcon sx={{ color: "gray" }} fontSize="inherit" />
        </IconButton>
        <DialogTitle sx={{ fontSize: "28px" }} textAlign={"center"}>
        {paymentData?.status === "pending" ? "Verify Payment" : "Edit Payment"}
        </DialogTitle>
        <DialogContent>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
              minWidth: "450px",
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Reg Id"
              placeholder="Reg Id"
              value={paymentData.RegId || ""}
              inputProps={{ readOnly: true }}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Name"
              placeholder="Name"
              value={paymentData.name || ""}
              inputProps={{ readOnly: true }}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Email"
              placeholder="Email"
              value={paymentData.email || ""}
              inputProps={{ readOnly: true }}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Payment For"
              placeholder="Payment For"
              value={paymentData.paymentFor || ""}
              inputProps={{ readOnly: true }}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="order Id"
              placeholder="order Id"
              value={paymentData.orderId || ""}
              inputProps={{ readOnly: true }}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Amount"
              placeholder="Amount"
              id="amount"
              value={data.amount || ""}
              onChange={handleChange}
              required
            />
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Transaction Id"
              placeholder="Transaction Id"
              id="transactionId"
              value={data.transactionId || ""}
              onChange={handleChange}
              required
            />
            <TextField
              variant="outlined"
              fullWidth
              type="date"
              label="settlement Date"
              placeholder="settlement Date"
              id="settlementDate"
              value={data.settlementDate || ""}
              onChange={handleChange}
              required
            />

            <Button
              disabled={
                isLoading ||
                data.amount === "" ||
                data.settlementDate === "" ||
                data.transactionId === ""
              } // Disable the button when submitting
              onClick={handleSubmit}
              sx={{ px: "60px", mt: "40px" }}
              variant="contained"
              color={paymentData?.status === "pending" ? "success" : "primary"}
            >
              {paymentData?.status === "pending" ? "Verify" : "Update"}
            </Button>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
}
