import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Login({setUser}) {
  const [creds, setCreds] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (key) => {
    key.preventDefault();
    setCreds({ ...creds, [key.target.id]: key.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
        if(!creds?.email || !creds?.password){
            alert("Invalid Details")
            return
        }
        const response = await axios.post("/api/v1/login",creds);
        setUser(response.data?.user)
    } catch (error) {
        alert(error?.response?.data?.message)
    }
  };

  const fetchUser = async (e) => {
    try {
        const response = await axios.get("/api/v1/my-paper");
        setUser(response.data?.user)
    } catch (error) {
        console.log(error?.response?.data?.message)
    }
  };

  document.onkeydown = checkKey;

  function checkKey(e) {

    e = e || window.event;

    if (e.keyCode === 13) {
      loginHandler(e);
    }

  }


  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height:"calc(100vh - 0px)",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "10px",
            color: "#2d2d2d",
          }}
        >
          Login
        </Typography>
        <Paper
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "400px",
            "@media (max-width: 550px)": {
              minWidth: "90%",
            },
          }}
        >
          <TextField
            id="email"
            type="text"
            label="Enter your  Email"
            placeholder="Email"
            value={creds.email || ""}
            onChange={handleChange}
            sx={{
              mt: 2,
              width: "100%",
            }}
          />
          <FormControl
            variant="outlined"
            sx={{
              mt: 2,
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="password"
              placeholder="*****"
              type={showPassword ? "text" : "password"}
              value={creds.password || ""}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <Button
            variant="contained"
            sx={{
              width: "100%",
              my: "20px",
            }}
            onClick={loginHandler}
          >
            Log in
          </Button>
          <Typography
          textAlign={"center"}
                sx={{fontSize:"16px", display:"flex", alignItems:"center", gap:"7px"}} >
            Don't have an account? <Link to="/register" style={{ textDecoration: 'none' }}><Typography
                sx={{color:"#0095f6",
                fontSize:"18px",
                fontWeight:600,
                cursor:"pointer"}} >
                Register
            </Typography>
            </Link>
            </Typography>
            <Link to="/forgot-password" style={{ textDecoration: 'none' }}><Typography
                sx={{color:"#2851a3",
                mt:"10px",
                fontSize:"16px",
                fontWeight:600,
                cursor:"pointer"}} >
                Forgot Password?
            </Typography>
            </Link>
        </Paper>
      </Container>
    </>
  );
}
