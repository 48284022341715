import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Paper,
  TextField,
  FormGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  Button,
  IconButton,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EditSubmission({ user, setUser }) {
  let navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    navigate("/thank-you");
  };

  const [pdfFile, setPdfFile] = useState("");

  const [paperData, setPaperData] = useState({
    title: "",
    abstract: "",
    keywords: "",
  });
  const [author1, setAuthor1] = useState({});
  const [author2, setAuthor2] = useState();
  const [author3, setAuthor3] = useState();
  const [author4, setAuthor4] = useState();
  const [author5, setAuthor5] = useState();
  const [author6, setAuthor6] = useState();
  const [author7, setAuthor7] = useState();

  const [authorSno, setAuthorSno] = useState(1);

  const [isLoading, setisLoading] = useState(false);

  const [wordCount, setWordCount] = useState(0);

  const [keywordsCount, setKeywordsCount] = useState(0);

  const countWords = (count) => {
    if (count.length === 0) {
      return 0;
    } else {
      count = count.replace(/(^\s*)|(\s*$)/gi, "");
      count = count.replace(/[ ]{2,}/gi, " ");
      count = count.replace(/\n /, "\n");
      return count.split(" ").length;
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setPdfFile(Reader.result);
      }
    };
  };

  const handleChange = (key) => {
    key.preventDefault();
    setPaperData({ ...paperData, [key.target.id]: key.target.value });
  };

  const handleAddAuthor = () => {
    if (authorSno < 7) {
      setAuthorSno(authorSno + 1);
    }
  };

  const logoutHandler = async (e) => {
    try {
      const response = await axios.get("/api/v1/logout");
      setUser(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  const handleSubmit = async () => {
    try {
      if( (!paperData?.title || !paperData?.abstract) && !pdfFile){
        alert("Missing Details");
        return;
      }
      if (
        !paperData?.keywords ||
        !author1?.firstName ||
        !author1?.lastName ||
        !author1?.email ||
        !author1?.region ||
        !author1?.affiliation
      ) {
        alert("Missing Details");
        return;
      }
      if (wordCount > 350) {
        alert("Abstract exceeds the word limit");
        return;
      }
      if (keywordsCount < 2) {
        alert("Include at least 3 keywords");
        return;
      }
      setisLoading(true);
      const response = await axios.put(`/api/v1/paper/${params.id}`, {
        title: paperData?.title,
        abstract: paperData?.abstract,
        keywords: paperData?.keywords,
        file:pdfFile,
        authors: [
          author1,
          author2,
          author3,
          author4,
          author5,
          author6,
          author7,
        ],
      });
      setisLoading(false);
      setUser(response.data?.user);
      setOpen(true);
      // navigate("/thank-you")
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  const fetchPaper = async (e) => {
    try {
      const response = await axios.get(`/api/v1/paper/${params.id}`);
      console.log(response.data?.paper);
      setPaperData(response.data?.paper);
      setAuthor1(response.data?.paper?.authors[0]);
      setAuthor2(response.data?.paper?.authors[1]);
      setAuthor3(response.data?.paper?.authors[2]);
      setAuthor4(response.data?.paper?.authors[3]);
      setAuthor5(response.data?.paper?.authors[4]);
      setAuthor6(response.data?.paper?.authors[5]);
      setAuthor7(response.data?.paper?.authors[6]);
      if (response.data?.paper?.authors[6]?.firstName) {
        setAuthorSno(7);
      } else if (response.data?.paper?.authors[5]?.firstName) {
        setAuthorSno(6);
      } else if (response.data?.paper?.authors[4]?.firstName) {
        setAuthorSno(5);
      } else if (response.data?.paper?.authors[3]?.firstName) {
        setAuthorSno(4);
      } else if (response.data?.paper?.authors[2]?.firstName) {
        setAuthorSno(3);
      } else if (response.data?.paper?.authors[1]?.firstName) {
        setAuthorSno(2);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchPaper();
  }, []);

  useEffect(() => {
    setWordCount(countWords(paperData?.abstract));
    setKeywordsCount((paperData.keywords.match(/,/g) || []).length);
  }, [paperData]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Saved Successfully
        </Alert>
      </Snackbar>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mt: "20px" }}
        >
          {/* <Typography sx={{fontSize:"20px", color:"#2d2d2d"}}>Hi, {user?.name}</Typography>
          <Button variant="contained" onClick={logoutHandler}>Logout</Button> */}
          <IconButton onClick={() => navigate("/thank-you")}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "40px",
            color: "#2d2d2d",
          }}
        >
          EDIT SUBMISSION
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            mb: "10px",
            color: "#2d2d2d",
          }}
        >
          Author Information
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "#2d2d2d" }}>
          For each author please fill out the form below. Some items on the form
          are explained here:
          <br />
          <ul>
            <li>
              <b>Email address</b> will only be used for communication with the
              authors. It will not appear in public Web pages of this
              conference. The email address can be omitted for not corresponding
              authors. These authors will also have no access to the submission
              page.
            </li>
            <li>
              <b>Web page</b> can be used on the conference Web pages, for
              example, for making the program. It should be a Web page of the
              author, not the Web page of her or his organization.
            </li>
            <li>
              Each author marked as a <b>corresponding author</b> will receive
              email messages from the system about this submission. There must
              be at least one corresponding author.
            </li>
          </ul>
        </Typography>

        <AuthorForm
          i={authorSno}
          setSno={setAuthorSno}
          sno={1}
          setAuthor={setAuthor1}
          author={author1}
        />
        {authorSno > 1 ? (
          <AuthorForm
            i={authorSno}
            setSno={setAuthorSno}
            sno={2}
            setAuthor={setAuthor2}
            author={author2}
          />
        ) : (
          ""
        )}
        {authorSno > 2 ? (
          <AuthorForm
            i={authorSno}
            setSno={setAuthorSno}
            sno={3}
            setAuthor={setAuthor3}
            author={author3}
          />
        ) : (
          ""
        )}
        {authorSno > 3 ? (
          <AuthorForm
            i={authorSno}
            setSno={setAuthorSno}
            sno={4}
            setAuthor={setAuthor4}
            author={author4}
          />
        ) : (
          ""
        )}
        {authorSno > 4 ? (
          <AuthorForm
            i={authorSno}
            setSno={setAuthorSno}
            sno={5}
            setAuthor={setAuthor5}
            author={author5}
          />
        ) : (
          ""
        )}
        {authorSno > 5 ? (
          <AuthorForm
            i={authorSno}
            setSno={setAuthorSno}
            sno={6}
            setAuthor={setAuthor6}
            author={author6}
          />
        ) : (
          ""
        )}
        {authorSno > 6 ? (
          <AuthorForm
            i={authorSno}
            setSno={setAuthorSno}
            sno={7}
            setAuthor={setAuthor7}
            author={author7}
          />
        ) : (
          ""
        )}
        {authorSno < 7 ? (
          <Button
            onClick={handleAddAuthor}
            sx={{ mt: 3 }}
            startIcon={<AddIcon />}
          >
            {" "}
            Add Author{" "}
          </Button>
        ) : (
          ""
        )}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            mb: "10px",
            mt: "60px",
            color: "#2d2d2d",
          }}
        >
          Title and Abstract
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "#2d2d2d" }}>
          The title and the abstract should be entered as plain text, they
          should not contain HTML elements.
        </Typography>
        <Paper
          sx={{
            p: 4,
            mt: "20px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
            "@media (max-width: 450px)": {
              mt: "20px",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <TextField
              variant="outlined"
              fullWidth
              id="title"
              type="text"
              label="Title"
              placeholder="Title"
              value={paperData.title || ""}
              onChange={handleChange}
              required
            />
            <TextField
              variant="outlined"
              fullWidth
              id="abstract"
              type="text"
              label="Abstract"
              placeholder="Abstract"
              value={paperData.abstract || ""}
              onChange={handleChange}
              error={wordCount > 350}
              helperText={wordCount + " / 350 words"}
              required
              multiline
              rows={8}
            />
          </Box>
        </Paper>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            mb: "10px",
            mt: "70px",
            color: "#2d2d2d",
          }}
        >
          Keywords
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "#2d2d2d" }}>
          Type a list of keywords (also known as key phrases or key terms),{" "}
          <b>Use commas to separate keywords</b> to characterize your
          submission. You should specify at least three keywords.
        </Typography>
        <Paper
          sx={{
            p: 4,
            borderRadius: "10px",
            mt: "20px",
            maxWidth: "500px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
            "@media (max-width: 450px)": {
              mt: "20px",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <TextField
              variant="outlined"
              fullWidth
              id="keywords"
              type="text"
              label="Keywords"
              placeholder="Keywords"
              value={paperData.keywords || ""}
              onChange={handleChange}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              required
              multiline
              rows={8}
              defaultValue={"Line1\nLine2"}
            />
          </Box>
        </Paper>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            mb: "10px",
            mt: "70px",
            color: "#2d2d2d",
          }}
        >
          Files
        </Typography>
        {paperData?.fileUrl ? (
          <Typography sx={{ fontSize: "18px" }}>
            <b>Uploaded File : </b>{" "}
            <a
              style={{ textDecoration: "none" }}
              href={paperData?.fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="text" startIcon={<PictureAsPdfIcon />}>
                View
              </Button>
            </a>
          </Typography>
        ) : (
          ""
        )}
        <Paper
          sx={{
            p: 4,
            borderRadius: "10px",
            mt: "20px",
            maxWidth: "500px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
            "@media (max-width: 450px)": {
              mt: "20px",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Typography sx={{ fontSize: "16px", color: "#2d2d2d" }}>
              <b>Paper.</b> Re-Upload your paper. The paper must be in PDF format
              (file extension .pdf)
            </Typography>
            <input
              variant="outlined"
              fullWidth
              accept=".pdf"
              onChange={handleFileChange}
              type="File"
            />
          </Box>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
          <Button
            disabled={isLoading}
            onClick={handleSubmit}
            sx={{ px: "60px", mt: "40px" }}
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Container>
    </>
  );
}

function AuthorForm({ i, sno, setSno, author, setAuthor }) {
  //   const [author, setAuthor] = useState({ firstName: "", lastName: "",email:"", region:"",affiliation:"",webPage:"" });

  const handleChange = (key) => {
    key.preventDefault();
    setAuthor({ ...author, [key.target.id]: key.target.value });
  };

  return (
    <>
      <Paper
        sx={{
          p: 4,
          mt: "50px",
          borderRadius: "10px",
          maxWidth: "500px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
          "@media (max-width: 450px)": {
            mt: "20px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            Author {sno}
          </Typography>
          {i === sno && i !== 1 ? (
            <IconButton
              onClick={() => {
                setSno(sno - 1);
                setAuthor();
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            ""
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            mt: "20px",
          }}
        >
          <TextField
            variant="outlined"
            fullWidth
            id="firstName"
            type="text"
            label="First Name"
            placeholder="First Name"
            value={author?.firstName || ""}
            onChange={handleChange}
            required
          />
          <TextField
            variant="outlined"
            fullWidth
            id="lastName"
            type="text"
            label="Last Name"
            placeholder="Last Name"
            value={author?.lastName || ""}
            onChange={handleChange}
            required
          />
          <TextField
            variant="outlined"
            fullWidth
            id="email"
            type="text"
            label="Email"
            placeholder="Email"
            value={author?.email || ""}
            onChange={handleChange}
            required
          />
          <TextField
            variant="outlined"
            fullWidth
            id="region"
            type="text"
            label="Country/region"
            placeholder="Country/region"
            value={author?.region || ""}
            onChange={handleChange}
            required
          />
          <TextField
            variant="outlined"
            fullWidth
            id="affiliation"
            type="text"
            label="Affiliation"
            placeholder="Affiliation"
            value={author?.affiliation || ""}
            onChange={handleChange}
            required
          />
          <TextField
            variant="outlined"
            fullWidth
            id="webPage"
            type="text"
            label="Web page"
            placeholder="Web Page"
            value={author?.webPage || ""}
            onChange={handleChange}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    author?.isCorrespondingAuthor === undefined
                      ? true
                      : author?.isCorrespondingAuthor === true
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setAuthor({
                      ...author,
                      ["isCorrespondingAuthor"]: e.target.checked,
                    });
                  }}
                />
              }
              label="Corresponding author"
            />
          </FormGroup>
        </Box>
      </Paper>
    </>
  );
}
