import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
  IconButton,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function EmailDialogBox({ open, setOpen }) {

  const [emailData, setEmailData] = useState({subject:"POMS 2023 | XLRI Jamshedpur", message:""})

  const [emails, setEmails] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (key) => {
    key.preventDefault();
    setEmailData({ ...emailData, [key.target.id]: key.target.value });
  };

  const handleSubmit = async () => {
   try {
    setIsLoading(true)
    const response = await axios.post("/api/v1/user/email",{
      email:open,
      subject:emailData?.subject,
      message: emailData?.message
    })
    alert(response.data?.message)
    fetchEmails()
   } catch (error) {
    console.log(error)
    alert(error.message)
   } finally{
    setIsLoading(false)
   }
  };

  const fetchEmails = async () => {
    try {
      const response = await axios.get(`/api/v1/user/emails/${open}`)
      setEmails(response.data?.emails)
    } catch (error) {
     console.log(error)
    }
   };

   useEffect(() => {
    fetchEmails()
   }, [open])
   

  console.log(open);

  return (
    <>
      <Dialog
        sx={{ backdropFilter: "blur(10px)" }}
        open={open}
        onClose={handleClose}
      >
        <IconButton
          sx={{ position: "absolute", top: "0px", right: "0px" }}
          aria-label="delete"
          onClick={handleClose}
          size="large"
        >
          <CloseIcon sx={{ color: "gray" }} fontSize="inherit"/>
        </IconButton>
        <DialogTitle sx={{ fontSize: "28px" }} textAlign={"center"}>
          Email
        </DialogTitle>
        <DialogContent>
        <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
              minWidth:"450px"
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="subject"
              type="text"
              label="Subject"
              placeholder="Subject"
              value={emailData.subject || ""}
              onChange={handleChange}
              required
            />

            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              id="message"
              type="text"
              label="Message"
              placeholder="Message"
              value={emailData.message || ""}
              onChange={handleChange}
              required
            />
            <Button
            disabled={isLoading || emailData.subject === "" || emailData.message === ""} // Disable the button when submitting
            onClick={handleSubmit}
            sx={{ px: "60px", mt: "40px" }}
            variant="contained"
          >
            send email
          </Button>
          </Paper>
          <Typography
            textAlign={"center"}
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#4d4d4d",
              mt: "30px",
            }}
          >
            Previous Emails
          </Typography>
          
            {emails?.map((e) => {
              return (
                <>
                <Paper
            sx={{
              p: 2,
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
              my:"10px",
              maxWidth:"450px"
            }}
          >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      color: "#2d2d2d",
                    }}
                  >
                    <b>Subject : </b>
                    {e?.subject} <br/>
                    <b>Message : </b>
                    {e?.message}
                  </Typography>
                  </Paper>
                
                </>
              );
            })}
        </DialogContent>
      </Dialog>
    </>
  );
}
