import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Avatar,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const academicians = [
  { name: "Prof. Debjit Roy", location: "IIM, Ahmedabad", img: "avatar1" },
  {
    name: "Prof. Jayashankar M. Swaminathan",
    location:
      "GlaxoSmithKline Distinguished Professor of Operations, Kenan-Flagler Business School, at the University of North Carolina at Chapel Hill",
    img: "avatar2",
  },
  // {
  //   name: "Prof. Nagesh N. Murthy",
  //   location:
  //     "Roger Engemann Professor in the Department of Operations and Business Analytics at the Lundquist College of Business",
  //   img: "avatar3",
  // },
  {
    name: "Prof. Priyank Yadav",
    location:
      "Assistant Professor, Department of Urology and Renal Transplantation Sanjay Gandhi Postgraduate Institute of Medical Sciences, Lucknow",
    img: "avatar4",
  },
  {
    name: "Prof. Priyank Arora",
    location:
      " Assistant Professor, Management Science Department, Darla Moore School of Business, University of South Carolina",
    img: "avatar5",
  },
  {
    name: "Prof. Rajiv Srivastava",
    location:
      "Professor (Retd.) & Adjunct Faculty, Operations Management,  IIM Lucknow",
    img: "avatar6",
  },
  {
    name: "Prof. Subodha Kumar",
    location:
      "Paul R. Anderson Distinguished Chair Professor of Statistics, Operations, and Data Science and the Founding Director of the Center for Business Analytics and Disruptive Technologies at Temple University’s Fox School of Business",
    img: "avatar7",
  },
  {
    name: "Sushil Gupta",
    location:
      "Ph.D. Professor, Department of Decision Sciences Executive Director, Production and Operations Management Society College of Business Administration Florida International University RB 250, 11200 SW 8th Street",
    img: "avatar12",
  },
  {
    name: "Nagesh N. Murthy",
    location:
      "Roger Engemann Professor President - POMS Associate Executive Director – POMS, Global Initiatives and Outreach Operations and Business Analytics Department Charles Lundquist College of Business 468 Lillis Business Complex",
    img: "avatar13",
  },

  {
    name: "Prof Sumit Sarkar",
    location:
      "Prof. Sumit Sarkar is a Professor in Economics area at XLRI Jamshedpur.",
    img: "Prof-Sumit-Sarkar-xlri",
  },

  {
    name: "Prof Vinod Singhal",
    location:
      "Charles W. Brady Chair Professor of Operations Management at the Scheller College of Business at Georgia Institute of Technology, Atlanta, USA",
    img: "Prof-Vinod-Singhal",
  },

  {
    name: "Prof Ray Venkataraman",
    location:
      "Department Chair, Marketing and Project and Supply Chain Management programs Professor of Management, Project and Supply Chain Management and MPM programs",
    img: "Prof-Ray-Venkataraman",
  },
];

const practitioners = [
  {
    name: "Vadiraj Kulkarni",
    location:
      " Divisional Chief Executive,I T C Paperboards & Specialty Papers Division",
    img: "avatar8",
  },
  {
    name: "Ashok Priyadarshi",
    location: "Sr. Vice President -HR & CSR , Alkem Laboratories Ltd.",
    img: "avatar9",
  },
  {
    name: "Harini Muralidharan",
    location: "Human Resources Director, Dentsu",
    img: "avatar10",
  },
  { name: "Q Tauheed", location: "Chief HRBP, Tata Steel", img: "avatar11" },
  { name: "Madhumitha Venkataraman", location: "DEI Expert", img: "Madhumitha-Venkataraman" },

  { name: "Rajesh Kumar", location: "Director, Business Analytics at Philips", img: "Rajesh-Kumar" },
  { name: "Prawesh K Jha", location: "Practice Leader and Head of Blockchain Services at IBM", img: "Prawesh-K-Jha" },
  { name: "Sarajit Jha", location: "Chief Business Transformation & Digital Solutions at Tata Steel", img: "Sarajit-Jha" },
  { name: "Srihari Kaninghat", location: "Head - Digital & Process Excellence Manufacturing & Supply Chain", img: "Srihari-Kaninghat" },
  { name: "Saroj Kumar Singh", location: "Tata Steel", img: "Saroj-Kumar-Singh" },
  { name: "Chirantan Bhandyopadhyay", location: "Head Product Line Operations MHCV at Tata Motors", img: "CHIRANTAN-BANDYOPADHYAY" },
  { name: "Anirban Ghosh", location: "Head - Centre for Sustainability, Mahindra University Chief Sustainability Officer,Mahindra Group", img: "Anirban-Ghosh" }

];

export default function GuestSpeakers() {
  return (
    <>
      <Container
        id="tracks"
        maxWidth="xl"
        sx={{
          py: "100px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            color: "#1b4e9b",
            textDecoration: "underline 5px #bccf17",
            textUnderlineOffset: "15px",
            "@media (max-width: 750px)": {
              fontSize: "22px",
              "@media (max-width: 450px)": {
                fontSize: "18px",
                lineHeight: "50px",
                mt: "50px",
              },
            },
          }}
        >
          CHIEF GUEST & GUEST OF HONOUR
        </Typography>
        <Box sx={{ display: "flex", gap: "25px", justifyContent: "center", pt: "100px" }}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px",
              "@media (max-width: 750px)": {
                p: 2,
              },
            }}
          >
            <Avatar
              alt="Mr Avneesh Gupta"
              src={require("../../components/assets/guests/avatar2.jpeg")}
              sx={{
                height: "200px",
                width: "200px",
                border: "1px solid gray",
                "@media (max-width: 750px)": {
                  height: "100px",
                  width: "100px",
                },
              }}
            />
            <Typography
              textAlign={"center"}
              sx={{
                mb: 1,
                fontSize: "20px",
                mt: "20px",
                "@media (max-width: 750px)": {
                  fontSize: "16px",
                },
              }}
            >
              Mr Avneesh Gupta
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{
                fontSize: "16px",
                color: "#8d8d8d",
                "@media (max-width: 750px)": {
                  fontSize: "14px",
                },
              }}
            >
              Vice President (TQM and Engineering & Projects ) <br />Tata Steel

              and Chairman of TRF Limited
            </Typography>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px",
              "@media (max-width: 750px)": {
                p: 2,
              },
            }}
          >
            <Avatar
              alt="Rahul Purwar"
              src={require("../../components/assets/guests/avatar1.jpg")}
              sx={{
                height: "200px",
                width: "200px",
                border: "1px solid gray",
                "@media (max-width: 750px)": {
                  height: "100px",
                  width: "100px",
                },
              }}
            />
            <Typography
              textAlign={"center"}
              sx={{
                mb: 1,
                fontSize: "20px",
                mt: "20px",
                "@media (max-width: 750px)": {
                  fontSize: "16px",
                },
              }}
            >
              Rahul Purwar
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{
                fontSize: "16px",
                color: "#8d8d8d",
                "@media (max-width: 750px)": {
                  fontSize: "14px",
                },
              }}
            >
              Secretary, Department of Higher and <br /> Technical Education, Government of Jharkhand
            </Typography>
          </Paper>
        </Box>
        </Container>
        <Box sx={{ background: "#d6ffe1" }}>
        <Container
        maxWidth="xl"
        sx={{
          py: "100px",
          minHeight: "100vh",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            color: "#1b4e9b",
            textDecoration: "underline 5px #bccf17",
            textUnderlineOffset: "15px",
            "@media (max-width: 750px)": {
              fontSize: "22px",
              "@media (max-width: 450px)": {
                fontSize: "18px",
                lineHeight: "50px",
              },
            },
          }}
        >
          SPEAKERS
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            color: "#1b4e9b",
            mt: "70px",
            ml: "50px",
            textDecoration: "underline 3px #bccf17",
            textUnderlineOffset: "10px",
            "@media (max-width: 750px)": {
              fontSize: "20px",
              "@media (max-width: 450px)": {
                fontSize: "16px",
                lineHeight: "50px",
                ml: "0px",
                mt: "30px",
              },
            },
          }}
        >
          Academicians
        </Typography>
        <Box
          sx={{
            display: "grid",
            mx: "50px",
            mt: "70px",
            alignItems: "stretched",
            gridTemplateColumns: "repeat(4,23%)",
            justifyContent: "space-between",
            "@media (max-width: 1200px)": {
              gridTemplateColumns: "repeat(3,32%)",
              gap: "10px",
              "@media (max-width: 700px)": {
                gridTemplateColumns: "repeat(2,49%)",
                gap: "10px",
                mt: "20px",
                mx: "0px",
              },
            },
          }}
        >
          {academicians?.map((e) => {
            return (
              <>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    mb: 5,
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px",
                    "@media (max-width: 750px)": {
                      p: 2,
                      mb: 2,
                    },
                  }}
                >
                  <Avatar
                    alt=""
                    src={require(`../../components/assets/speakers/${e?.img}.jpg`)}
                    sx={{
                      height: "150px",
                      width: "150px",
                      border: "1px solid gray",
                      "@media (max-width: 750px)": {
                        height: "100px",
                        width: "100px",
                      },
                    }}
                  />
                  <Typography
                    textAlign={"center"}
                    sx={{
                      mb: 1,
                      fontSize: "18px",
                      mt: "20px",
                      "@media (max-width: 750px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    {e?.name}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      fontSize: "14px",
                      color: "#8d8d8d",
                      "@media (max-width: 750px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    ({e?.location})
                  </Typography>
                </Paper>
              </>
            );
          })}
        </Box>

        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            color: "#1b4e9b",
            mt: "50px",
            ml: "50px",
            textDecoration: "underline 3px #bccf17",
            textUnderlineOffset: "10px",
            "@media (max-width: 750px)": {
              fontSize: "20px",
              "@media (max-width: 450px)": {
                fontSize: "16px",
                lineHeight: "50px",
                ml: "0px",
                mt: "30px",
              },
            },
          }}
        >
          Practitioners
        </Typography>
        <Box
          sx={{
            display: "grid",
            mx: "50px",
            mt: "70px",
            alignItems: "stretched",
            gridTemplateColumns: "repeat(4,23%)",
            justifyContent: "space-between",
            "@media (max-width: 1200px)": {
              gridTemplateColumns: "repeat(3,32%)",
              gap: "10px",
              "@media (max-width: 700px)": {
                gridTemplateColumns: "repeat(2,49%)",
                gap: "10px",
                mt: "20px",
                mx: "0px",
              },
            },
          }}
        >
          {practitioners?.map((e) => {
            return (
              <>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    mb: 5,
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px",
                    "@media (max-width: 750px)": {
                      p: 2,
                      mb: 2,
                    },
                  }}
                >
                  <Avatar
                    alt=""
                    src={require(`../../components/assets/speakers/${e?.img}.jpg`)}
                    sx={{
                      height: "150px",
                      width: "150px",
                      border: "1px solid gray",
                      "@media (max-width: 750px)": {
                        height: "100px",
                        width: "100px",
                      },
                    }}
                  />
                  <Typography
                    textAlign={"center"}
                    sx={{
                      mb: 1,
                      fontSize: "18px",
                      mt: "20px",
                      "@media (max-width: 750px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    {e?.name}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      fontSize: "14px",
                      color: "#8d8d8d",
                      "@media (max-width: 750px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    ({e?.location})
                  </Typography>
                </Paper>
              </>
            );
          })}
        </Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            color: "#1b4e9b",
            mt: "50px",
            ml: "50px",
            textDecoration: "underline 3px #bccf17",
            textUnderlineOffset: "10px",
            "@media (max-width: 750px)": {
              fontSize: "20px",
              "@media (max-width: 450px)": {
                fontSize: "16px",
                lineHeight: "50px",
                ml: "0px",
                mt: "30px",
              },
            },
          }}
        >
          Policymakers
        </Typography>
        <Box
          sx={{
            display: "grid",
            mx: "50px",
            mt: "70px",
            alignItems: "stretched",
            gridTemplateColumns: "repeat(4,23%)",
            justifyContent: "space-between",
            "@media (max-width: 1200px)": {
              gridTemplateColumns: "repeat(3,32%)",
              gap: "10px",
              "@media (max-width: 700px)": {
                gridTemplateColumns: "repeat(2,49%)",
                gap: "10px",
                mt: "20px",
                mx: "0px",
              },
            },
          }}
        >
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    mb: 5,
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px",
                    "@media (max-width: 750px)": {
                      p: 2,
                      mb: 2,
                    },
                  }}
                >
                  <Avatar
                    alt=""
                    src={require(`../../components/assets/speakers/avatar14.jpg`)}
                    sx={{
                      height: "150px",
                      width: "150px",
                      border: "1px solid gray",
                      "@media (max-width: 750px)": {
                        height: "100px",
                        width: "100px",
                      },
                    }}
                  />
                  <Typography
                    textAlign={"center"}
                    sx={{
                      mb: 1,
                      fontSize: "18px",
                      mt: "20px",
                      "@media (max-width: 750px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Shri Jitendra Kumar Singh
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      fontSize: "14px",
                      color: "#8d8d8d",
                      "@media (max-width: 750px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    (IAS Secretary. Department of Industries)
                  </Typography>
                </Paper>
        </Box>
      </Container>
      </Box>
    </>
  );
}
