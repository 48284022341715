import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Paper,
  IconButton,
  Button
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function PaperDetails() {

    const params = useParams();

    const [user, setUser] = useState([])

    let navigate = useNavigate()

    const fetchPaper = async (e) => {
        try {
            const response = await axios.get(`/api/v1/paper/${params.id}`);
            console.log(response.data?.paper)
            setUser(response.data?.paper)
            
        } catch (error) {
            console.log(error?.response?.data?.message)
        }
      };
    
      useEffect(() => {
       fetchPaper()
      }, [])

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
     <Box sx={{display:"flex", justifyContent:"space-between",mt:"20px"}}>
          <IconButton onClick={()=>navigate(-1)}>
            <ArrowBackIcon/>
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "25px",
            fontWeight: "600",
            my: "50px",
            color: "#6d6d6d",
          }}
        >
          User Details
        </Typography>
        <Paper
                sx={{
                  p: 4,
                  mt: "20px",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
                  "@media (max-width: 1000px)": {
                   p:2
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
                >
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>App. No. : </b>
                    {user?.createdBy?.appNo}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Name : </b>
                    {user?.createdBy?.name}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Email : </b> {user?.createdBy?.email}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Contact No. : </b> {user?.createdBy?.phone}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Created At : </b> <Moment format="HH:mm DD/MM/YYYY">
                      {user?.createdAt}
                      </Moment>
                  </Typography>
                </Box>
              </Paper>
              <Paper
                sx={{
                  p: 4,
                  mt: "20px",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
                  "@media (max-width: 1000px)": {
                   p:2
                  },
                }}
              >
                {/* <Typography textAlign={"center"} sx={{fontSize:"24px", fontWeight:"600",color:"#4d4d4d", mb:"10px"}}>Paper {i+1}</Typography> */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
                >
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Title : </b>
                    {user?.title}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Abstract : </b> {user?.abstract}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Keywords : </b> {user?.keywords}
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <b>Uploaded File : </b> {user?.fileUrl?<a style={{textDecoration:"none"}} href={user?.fileUrl} target="_blank" rel="noopener noreferrer"><Button variant="text" startIcon={<PictureAsPdfIcon />}>View</Button></a>:"No file uploaded"}
                  </Typography>
                </Box>
                <Typography
                  textAlign={"center"}
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "#4d4d4d",
                    mt: "30px",
                  }}
                >
                  Authors
                </Typography>
                <Box sx={{ display:"grid", alignItems:"stretched", gridTemplateColumns: "repeat(3,auto)", mt:"20px", gap:"20px", "@media (max-width: 1000px)": {
                             gridTemplateColumns: "repeat(1,100%)",
                              mt:"0px"
                            }, }}>
                  {user?.authors?.map((a) => {
                    return (
                      <>{
                        a?.firstName?
                      
                        <Paper
                          sx={{
                            p: 4,
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
                            width: "80%",
                          }}
                        >
                          <Typography>
                            <b>Name :</b>
                            {a?.firstName} {a?.lastName}
                          </Typography>
                          <Typography>
                            <b>Email :</b>
                            {a?.email}
                          </Typography>
                          <Typography>
                            <b>Region :</b>
                            {a?.region}
                          </Typography>
                          <Typography>
                            <b>Affiliation :</b>
                            {a?.affiliation}
                          </Typography>
                          <Typography>
                            <b>Webpage :</b>
                            {a?.webPage}
                          </Typography>
                          <Typography>
                          {a?.isCorrespondingAuthor?
                            <b>Corresponding author</b>:""
                            }
                          </Typography>
                        </Paper>:""}
                      </>
                    );
                  })}
                </Box>
              </Paper>
      </Container>
    </>
  );
}
