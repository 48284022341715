import React from 'react'
import { Container, Typography,Box} from "@mui/material"

export default function VenueAccomodation() {
  return (
    <>
      <Box sx={{ background: "rgb(213 237 252)" }}>
        <Container id="venue" maxWidth="xl" sx={{ py: "70px" ,"@media (max-width: 450px)": {
                 py:"30px"
                }}}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              color: "#1b4e9b",
              textDecoration: "underline 5px #bccf17",
              textUnderlineOffset: "15px",
              "@media (max-width: 750px)": {
                fontSize: "22px",
                "@media (max-width: 450px)": {
                  fontSize: "18px",
                  lineHeight:"50px"
                }
              }
            }}
          >
           VENUE AND ACCOMODATION
          </Typography>
          <Box sx={{display:"flex", justifyContent:"space-between", gap:"50px", mt:"50px", alignItems:"center",  "@media (max-width: 900px)": {
                flexDirection:"column",
                "@media (max-width: 450px)": {
                  mt:"20px"
                 }
              }}}>
        
            <img
              style={{ borderRadius: "10px", width: "auto", height:"100%", maxWidth:"50%",  "@media (max-width: 900px)": {
                maxWidth:"100%",
              } }}
              src={require("../../../components/assets/institute.jpg")}
              alt=""
            /> 
            <Typography sx={{ fontSize: "16px", color: "#2d2d2d",textAlign:"justify","@media (max-width: 900px)": {
               fontSize:"14px"
            }
            }} className="text">
            Conference will be organized at the beautiful campus of Xavier School of Management (XLRI), Jamshedpur.
              <br />
              <br />
              Details:
XLRI
C. H. Area (East)
Jamshedpur - 831001
Jharkhand, India
Phone : +91 657 665 3333
Fax: +91-657-2227814
              <br />
              <br />
              There are large number hotels available in the Jamshedpur city and nearby XLRI. In addition to these hotels
limited number of accommodations is also available at XLRI Jamshedpur Guest House.
<br />
              <br />
              For booking accommodation one needs to forward their request to the conference committee. The available
rooms will be allotted on First Come Firsts Serve basis. The rates for the guest accommodation (including
GST) are as follows:
              <br />
              <br />
              Twin sharing: Rs. 2688/- per night
              <br />
Single: Rs. 4480/- per night
            </Typography>

          </Box>
          <Typography sx={{ fontSize: "16px", color: "#2d2d2d", textAlign: "justify", "@media (max-width: 900px)": { fontSize: "14px" } }} className="text">
            <br />
            <br />
            For hotel accommodations, you can consider the following options near XLRI Jamshedpur:

            <ul>
              <li><a href="https://www.booking.com/hotel/in/ramada-jamshedpur-bistupur.html" target="_blank" rel="noopener noreferrer">Ramada Jamshedpur Bistupur</a></li>
              <li><a href="https://www.yatra.com/hotels/hotels-in-jamshedpur/ginger-hotel" target="_blank" rel="noopener noreferrer">Ginger Hotel Jamshedpur</a></li>
              <li><a href="https://www.thealcorhotel.com/accommodation/" target="_blank" rel="noopener noreferrer">The Alcor Hotel</a></li>
              <li><a href="https://thesonnet.com/jamshedpur/accomodation.php" target="_blank" rel="noopener noreferrer">The Sonnet Jamshedpur</a></li>
            </ul>

            Please click on the links above to check availability, rates, and make reservations directly through the respective hotel websites.
          </Typography>
        </Container>
        </Box>
    </>
  )
}
