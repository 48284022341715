import React from "react";

import {
  Container,
  Typography,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ConferenceTracks() {
  return (
    <>
      <Box sx={{ background: "rgb(213 237 252)" }}>
        <Container id="tracks" maxWidth="xl" sx={{ py: "70px","@media (max-width: 450px)": {
                 py:"30px"
                } }}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              color: "#1b4e9b",
              textDecoration: "underline 5px #bccf17",
              textUnderlineOffset: "15px",
              "@media (max-width: 750px)": {
                fontSize: "22px",
                "@media (max-width: 450px)": {
                  fontSize: "18px",
                  lineHeight:"50px"
                }
              }
            }}
          >
            CONFERENCE TRACKS
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#2d2d2d",
              textAlign: "justify",
              mt: "50px",
              "@media (max-width: 900px)": {
                fontSize:"14px",
                "@media (max-width: 450px)": {
                  mt:"20px"
                 }
               }
            }}
          >
            The conference welcomes submissions from all areas of Production and Operations Management, including, but not limited to, the following tracks:
          </Typography>
          <Typography sx={{ fontSize: "16px","@media (max-width: 450px)": {
               fontSize:"12px"
          }
          }} className="text">
                  <ul>
                    <li>Behavioral Operations Management</li>
                    <li>Building Resilient Operations</li>
                    <li>Business analytics and Data-driven Operations Management</li>
                    <li>Carbon neutrality, Sustainability, and Circular Economy</li>
                    <li>Digital technologies like Industry 4.0, Cloud Computing, AI/ML, and Blockchain for Sustainable Solutions</li>
                    <li>Digital transformation and the Role of Digital Technology in Reshaping Diversity, Equity, and Inclusion</li>
                    <li>Dynamic Pricing &amp; Revenue Management</li>
                    <li>Emerging Production and Operations Management Issues</li>
                    <li>Environmental legislation and other Regulatory Issues in Production and Operations Management</li>
                    <li>Gig Economy and Social Media Research</li>
                    <li>Healthcare Operations Management</li>
                    <li>Human-Focused and People-Centric Approach to Production and Operations Management</li>
                    <li>Humanitarian and Crisis Management</li>
                    <li>Innovation in Operations Management, New Product Development, and R &amp; D</li>
                    <li>Interface between Operations Management and other Management Disciplines like Information Systems, Marketing, Finance, and others</li>
                    <li>Learning from the Pandemic and Global Supply Chain Crisis</li>
                    <li>Platform Economics and Sharing Economy</li>
                    <li>Risk Management in Operations Management</li>
                    <li>Any other Issues in Production and Operations Management</li>
                  </ul>
                </Typography>
        </Container>
        </Box>
    </>
  );
}
