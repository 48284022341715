import React from "react";
import { Container, Typography,Box } from "@mui/material";

export default function CallForPaper() {
  return (
    <>
    <Box id="call-paper">
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            color: "#1b4e9b",
            textDecoration: "underline 5px #bccf17",
            textUnderlineOffset: "15px",
            "@media (max-width: 750px)": {
              fontSize: "22px",
              "@media (max-width: 450px)": {
                fontSize: "18px",
                lineHeight: "50px",
              },
            },
          }}
        >
          CALL FOR PAPERS
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#2d2d2d",
            mt: "40px",
            textAlign: "justify",
            "@media (max-width: 900px)": {
              fontSize: "14px",
              "@media (max-width: 450px)": {
                mt: "20px",
              },
            },
            }} className="text"
        >
          The POMS India Chapter Conference 2023 will be organized by XLRI -
          Xavier School of Management, India during 4th-6th December 2023. The
          conference will be held offline through in-person presentations on the
          XLRI campus in Jamshedpur, Jharkhand, India. With the overarching
          theme of “Envisioning the Future of Operations for Sustainability”,
          the conference endeavours to establish a collaborative platform that
          brings together scholars and researchers in the field of Production
          and Operations Management, and related disciplines to exchange research
          ideas, industry practices, and managerial insights. By facilitating
          this dynamic exchange, the conference aims to drive innovation and
          envision a future where the field of Production and Operations
          Management can contribute to sustainable practices.
        </Typography>
      </Container>
      </Box>
    </>
  );
}
