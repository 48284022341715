import React from 'react'

import { Container, Typography, Box, Paper } from "@mui/material";

export default function Sponsor() {
  return (
    <>
    <Container id="contact" maxWidth="xl" sx={{ py: "70px","@media (max-width: 450px)": {
                 py:"30px"
                } }}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              color: "#1b4e9b",
              textDecoration: "underline 5px #bccf17",
              textUnderlineOffset: "15px",
              "@media (max-width: 750px)": {
                fontSize: "22px",
                "@media (max-width: 450px)": {
                  fontSize: "18px",
                  lineHeight:"50px"
                }
              }
            }}
          >
           GOLD SPONSOR
          </Typography>
          <Paper sx={{ mt:"50px", borderRadius:"10px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
                "@media (max-width: 450px)": {
                  mt:"20px"
                 }}}>
                   <img
              style={{ borderRadius: "10px", width: "auto", height:"100%", maxWidth:"100%"
             }}
              src={require("../../../components/assets/sponsor.jpg")}
              alt=""
            /> 
          </Paper>
        </Container>
    </>
  )
}
