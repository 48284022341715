import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CountryDialogBox from "../../components/countryDialogBox";
import Moment from "react-moment";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Paper,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentButton from "../../components/paytm/paymentButton";

const programs = [
  // {
  //   name: "Academicians within India",
  //   amount: 8500,
  //   tax: 1530,
  // },
  // {
  //   name: "Research Scholars within India",
  //   amount: 3000,
  //   tax: 540,
  // },
  // {
  //   name: "Industry professionals",
  //   amount: 10000,
  //   tax: 1800,
  // },
  {
    name: "Academicians from outside India",
    amount: 21000,
    tax: 3780,
  },
  {
    name: "Research Scholars from outside India",
    amount: 12000,
    tax: 2160,
  },
];

export default function CheckoutPage() {
  let navigate = useNavigate();

  const params = useParams();

  const setProg = () => {
    if (params?.id === "academicians_from_outside_india") {
      return programs[0];
    } else if (params?.id === "research_scholars_from_outside_india") {
      return programs[1];
    } else if (params?.id === "test") {
      return {
        name: "test",
        amount: 1,
        tax: 0,
      };
    } else {
      return programs[0];
    }
  };

  //   const setProg = () => {
  //   if (params?.id === "academicians_within_india") {
  //     return programs[0];
  //   } else if (params?.id === "research_scholars_within_india") {
  //     return programs[1];
  //   } else if (params?.id === "industry_professionals") {
  //     return programs[2];
  //   } else if (params?.id === "academicians_from_outside_india") {
  //     return programs[3];
  //   } else if (params?.id === "research_scholars_from_outside_india") {
  //     return programs[4];
  //   } else if (params?.id === "test") {
  //     return {
  //       name: "test",
  //       amount: 1,
  //       tax: 0,
  //     };
  //   } else {
  //     return programs[0];
  //   }
  // };

  const [program, setProgram] = useState(setProg);

  const [isLoading, setIsLoading] = useState(false)

  const [open, setOpen] = useState(true);

  const [isIndian, setIsIndian] = useState(true);

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    regId: "",
  });

  const [isPhd, setIsPhd] = useState(false);

  const handleChange = (key) => {
    key.preventDefault();
    setUser({ ...user, [key.target.id]: key.target.value });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const response = await axios.post("/api/v1/payment/international",{
        name: user.name,
        email: user.email,
        phone: user.phone,
        regId: user.regId,
        isPhd:isPhd,
        event:program?.name,
        amount:program?.amount + program?.tax
      })
      console.log(response)
      alert(response.data?.message)
    } catch (error) {
      console.log(error)
      alert(error.message)
    } finally {
      setIsLoading(false)
    }
    
  }

  return (
    <>
      <CountryDialogBox
        open={open}
        setOpen={setOpen}
        isIndian={isIndian}
        setIsIndian={setIsIndian}
      />
      <Container
        maxWidth="lg"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mt: "20px" }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "25px",
            fontWeight: "600",
            my: "50px",
            color: "#6d6d6d",
          }}
        >
          Registation Form
        </Typography>

        <Paper
          sx={{
            p: 4,
            mt: "20px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          {isIndian ? (
            <iframe
              src="https://register.xlri.ac.in/public-form?formId=FORM-DYN-2023-US0MRS"
              width="100%"
              height="1000px"
              title="XLRI Website"
              frameborder="0"
              border="0"
            ></iframe>
          ) : (
            <>
              <TextField
                variant="outlined"
                fullWidth
                id="name"
                type="text"
                label="Name"
                placeholder="Name"
                value={user.name || ""}
                onChange={handleChange}
                required
              />
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                type="text"
                label="Email"
                placeholder="Email"
                value={user.email || ""}
                onChange={handleChange}
                required
              />
              <TextField
                variant="outlined"
                fullWidth
                id="phone"
                type="number"
                label="Phone No."
                placeholder="Phone No."
                value={user.phone || ""}
                onChange={handleChange}
                required
              />
              <TextField
                variant="outlined"
                fullWidth
                id="regId"
                type="text"
                label="Registration ID"
                placeholder="Registration ID"
                value={user.regId || ""}
                onChange={handleChange}
                required
              />
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Are you a PhD student?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={isPhd}
                  onChange={(e) => setIsPhd(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Programs</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={program?.name}
                  label="Programs"
                  onChange={(e) =>
                    setProgram(
                      programs?.filter((x) => {
                        if (x.name === e.target.value) return x;
                      })?.[0]
                    )
                  }
                >
                  {programs.map((item) => {
                    return <MenuItem value={item.name}>{item?.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              {/* <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  mb: "10px",
                  mt: "50px",
                  color: "#2d2d2d",
                }}
              >
                Payment Summary
              </Typography>
              <Typography>
                <b>Amount :</b> ₹{program?.amount}/- <br />
                <b>GST :</b> ₹{program?.tax}/- <br />
                <b>Total :</b> ₹{program?.amount + program?.tax}/-
              </Typography> */}


              {/* <PaymentButton
                isDisabled={
                  user.email === "" || user.name === "" || user.phone === "" || user.regId ===""
                    ? true
                    : false
                }
                event={program?.event}
                amount={program?.amount + program?.tax}
                name={user.name}
                email={user.email}
                phone={user.phone}
              /> */}
              <Button
                isDisabled={
                  user.email === "" ||
                  user.name === "" ||
                  user.phone === "" ||
                  user.regId === "" ||
                  isLoading
                    ? true
                    : false
                }
                variant="contained"
                sx={{ width: "250px" }}
                onClick={handleSubmit}
              >
                make paymant offline
              </Button>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
}
