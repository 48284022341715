import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import {
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Button,
  IconButton,
  Tabs,
  Tab,
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginDialogBox from "../../components/loginDialogBox";
import VerifyPaymentDialogBox from "../../components/verifyPaymentDialogBox";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function DaySchedule() {

    let navigate = useNavigate();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


  return (
    <>
     <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "20px",
            "@media (max-width: 1405px)": {
              mt: "30px",
            },
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "40px",
            color: "#2d2d2d",
          }}
        >
          POMS SCHEDULE 2023
        </Typography>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                  >
                    <Tab label="DAY 1 - 4th Dec, 2023" {...a11yProps(0)} />
                    <Tab label="DAY 2 - 5th Dec, 2023" {...a11yProps(1)} />
                    <Tab label="DAY 3 - 6th Dec, 2023" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <ScheduleTable day={"1"}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <ScheduleTable day={"2"}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <ScheduleTable day={"3"}/>
              </CustomTabPanel>
            </Box>
      </Container>
    </>
  )
}


function ScheduleTable({day}) {


    const [schedule, setSchedule] = useState([])
  
    const [isLoading, setIsLoading] = useState(false)
  
    const fetchSchedule = async (e) => {
      try {
        setIsLoading(true)
        const response = await axios.get(`/api/v1/schedule/day_${day}`);
        setSchedule(response.data?.schedule);
      } catch (error) {
        console.log(error);
      } finally{
        setIsLoading(false)
      }
    };
  
    useEffect(() => {
        fetchSchedule()
    }, []);
  
    return (
      <>
      {isLoading  ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height:"100vh"
              }}
            >
              <CircularProgress />
            </Box>
          ) :
        <TableContainer sx={styles.table} component={Paper}>
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead sx={{ background: "#D3D3D3" }}>
              <TableRow>
                <TableCell sx={styles.headTxt}>S.No.</TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Start Time (IST)
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                End Time (IST)
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Title of Talk
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Speakers
                </TableCell>
                <TableCell sx={styles.headTxt} align="left">
                  Venue
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule?.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={styles.bodyTxt} component="th" scope="row">
                    {i + 1}.
                  </TableCell>
                  <TableCell sx={styles.bodyTxt} align="left">
                    {row?.start_time}
                  </TableCell>
                  <TableCell sx={styles.bodyTxt} align="left">
                    {row?.end_time}
                  </TableCell>
                  <TableCell sx={styles.bodyTxt} align="left">
                    {row?.title}
                  </TableCell>
                  <TableCell sx={styles.bodyTxt} align="left">
                  {row?.speakers?row?.speakers:"-"}
                  </TableCell>
                  <TableCell sx={styles.bodyTxt} align="left">
                  {row?.venue?row?.venue:"-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
  }
      </>
    );
  }

  
const styles = {
    headTxt: {
      fontSize: "16px",
      fontWeight: "600",
    },
    bodyTxt: {
      fontSize: "15px",
      wordWrap: "break-word",
      maxWidth: "200px",
    },
    txt1: {
      fontWeight: "600",
      minWidth: "120px",
    },
    txt2: {
      wordWrap: "break-word",
      maxWidth: "180px",
    },
  };
  