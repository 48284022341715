import React from 'react'

import { Container, Typography, Box, Paper, Avatar } from "@mui/material";

export default function AxiomCommittee() {
  return (
    <Box sx={{ background: "#fef5f2" }}>
      <Container maxWidth="xl" sx={{
        py: "70px", "@media (max-width: 450px)": {
          py: "30px"
        }
      }} id="axiom">
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            color: "#1b4e9b",
            textDecoration: "underline 5px #bccf17",
            textUnderlineOffset: "15px",
            "@media (max-width: 750px)": {
              fontSize: "22px",
              "@media (max-width: 450px)": {
                fontSize: "18px",
                lineHeight: "50px"
              }
            }
          }}
        >
          AXIOM
        </Typography>
        <p sx={{ fontSize: "22", fontWeight: "bold" }}>(Association at XLRI for Industrial and Operations Management)</p>
        <Box sx={{
          display: "grid", mt: "70px", alignItems: "center", gridTemplateColumns: "repeat(2,auto)", gap: "100px", justifyContent: "center", "@media (max-width: 850px)": {
            gap: "20px",
            "@media (max-width: 550px)": {
              gap: "10px",
              mt: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }
          }
        }}>
          <Avatar variant="square" alt="AXIOM" src={require("../../../components/assets/axiom-senior.jpg")} sx={{
            height: "100%", width: "100%", borderRadius: "10px", "@media (max-width: 550px)": {
              height: "100%", width: "auto"
            }
          }} />
          <Avatar variant="square" alt="AXIOM" src={require("../../../components/assets/axiom-junior.jpg")} sx={{
            height: "100%", width: "100%", borderRadius: "10px", "@media (max-width: 550px)": {
              height: "100%", width: "auto"
            }
          }} />
        </Box>
      </Container>
    </Box>
  )
}
