import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Signup({setUser}) {
  const [creds, setCreds] = useState({ name:"", phone:"", email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (key) => {
    key.preventDefault();
    setCreds({ ...creds, [key.target.id]: key.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const signupHandler = async (e) => {
    e.preventDefault();
    try {
        if(!creds?.email || !creds?.password || !creds?.name || !creds?.phone){
            alert("Invalid Details")
            return
        }
        const response = await axios.post("/api/v1/register",creds);
        setUser(response.data?.user)
    } catch (error) {
        alert(error?.response?.data?.message)
    }
  };

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height:"calc(100vh - 0px)",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            my: "10px",
            color: "#2d2d2d",
          }}
        >
          Register
        </Typography>
        <Paper
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "400px",
            "@media (max-width: 550px)": {
              minWidth: "90%",
            },
          }}
        >
            <TextField
            id="name"
            type="text"
            label="Enter your  Name"
            placeholder="Name"
            value={creds.name || ""}
            onChange={handleChange}
            sx={{
              mt: 2,
              width: "100%",
            }}
          />
          <TextField
            id="phone"
            type="number"
            label="Enter your Contact No."
            placeholder="Contact No."
            value={creds.phone || ""}
            onChange={handleChange}
            sx={{
              mt: 2,
              width: "100%",
            }}
          />
          <TextField
            id="email"
            type="text"
            label="Enter your  Email"
            placeholder="Email"
            value={creds.email || ""}
            onChange={handleChange}
            sx={{
              mt: 2,
              width: "100%",
            }}
          />
          <FormControl
            variant="outlined"
            sx={{
              mt: 2,
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="password"
              placeholder="*****"
              type={showPassword ? "text" : "password"}
              value={creds.password || ""}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <Button
            variant="contained"
            sx={{
              width: "100%",
              my: "20px",
            }}
            onClick={signupHandler}
          >
            Register
          </Button>
          <Typography
          textAlign={"center"}
                sx={{fontSize:"16px"}} >
            Have an account? <Link to="/login" style={{ textDecoration: 'none' }}><Typography
                sx={{color:"#0095f6",
                fontSize:"18px",
                fontWeight:600,
                cursor:"pointer"}} >
                Login
            </Typography>
            </Link>
            </Typography>
        </Paper>
      </Container>
    </>
  );
}
