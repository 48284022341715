import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, Typography,Box,Paper, Button } from "@mui/material";

export default function PaperSubmission() {

  let navigate = useNavigate()

  return (
    <>
    <Container maxWidth="xl" sx={{ py: "70px" ,"@media (max-width: 450px)": {
                 py:"30px"
                }}} id="paper">
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            color: "#1b4e9b",
            textDecoration: "underline 5px #bccf17",
            textUnderlineOffset: "15px",
            "@media (max-width: 750px)": {
              fontSize: "22px",
              "@media (max-width: 450px)": {
                fontSize: "18px",
                lineHeight:"50px"
              }
            }
          }}
        >
          ABSTRACT SUBMISSION
        </Typography>
        <Paper elevation={3} sx={{p:4,mt:"50px", borderRadius:"10px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px",
                "@media (max-width: 450px)": {
                  mt:"20px"
                 }}}>
        <Typography sx={{ fontSize: "16px", color: "#2d2d2d", textAlign:"justify",mb:"20px","@media (max-width: 900px)": {
               fontSize:"14px"
              } }}>
            Please submit the abstract through below link or directly send on email address: poms2023@xlri.ac.in<br /><br />
            Format to send 100 words abstract through mail:
            <ul>
              <li>Title</li>
              <li>Authors name and Affiliation</li>
              <li>Abstract (100 words)</li>
              <li>Keywords (3-6 words)</li>
            </ul>
            <p>Format to send Full paper through mail:</p>
            <p><b>Full paper may contain following headings</b></p>
            <p>Title, Authors name and Affiliation. Abstract, Keywords, Introduction. Literature review, Model description. Proposition/ Theorems Lemma, Hypothesis development, Data description, Discussion. Managerial and Theoretical Implications. Conclusion</p>
            <hr />
          </Typography>
         {/* <a href="https://easychair.org/cfp/content.cgi?a=31326933;draft=1" target="_blank" rel="noopener noreferrer"><Button variant="contained">Submission Link</Button></a>  */}
          {/* <Button onClick={()=>navigate("./submission")} variant="contained">Submission Link</Button> */}
          </Paper>
      </Container>
    </>
  )
}
