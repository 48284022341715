import React from 'react'

import { Container, Typography,Box,Paper, Avatar} from "@mui/material";

export default function SteeringCommittee() {
  return (
    <>
      <Box sx={{ background: "rgb(213 237 252)" }}>
        <Container id="steering" maxWidth="xl" sx={{ py: "70px","@media (max-width: 450px)": {
                 py:"30px"
                } }}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              color: "#1b4e9b",
              textDecoration: "underline 5px #bccf17",
              textUnderlineOffset: "15px",
              "@media (max-width: 750px)": {
                fontSize: "22px",
                "@media (max-width: 450px)": {
                  fontSize: "18px",
                  lineHeight:"50px"
                }
              }
            }}
          >
           CONFERENCE STEERING COMMITTEE
          </Typography>
          <Box sx={{display:"grid",  mt:"70px", alignItems:"stretched", gridTemplateColumns: "repeat(5,18%)", justifyContent:"space-between","@media (max-width: 1200px)": {
                gridTemplateColumns: "repeat(3,32%)", gap:"10px",
                "@media (max-width: 450px)": {
                  mt:"20px"
                 }
              }}}>
            <Paper elevation={3} sx={{p:4, borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px","@media (max-width: 750px)": {
              p:2
              }}}>
                <Avatar alt="Fr S George, S J" src={require("../../../components/assets/avatar1.jpg")} sx={{height:"150px", width:"150px", border:"1px solid gray","@media (max-width: 750px)": {
               height:"100px", width:"100px"
              }}}/>
                <Typography textAlign={"center"} sx={{ mb:1, fontSize:"18px", mt:"20px","@media (max-width: 750px)": {
               fontSize:"14px"
              }}}>Fr S George, S J</Typography>
                <Typography textAlign={"center"} sx={{fontSize:"14px", color:"#8d8d8d","@media (max-width: 750px)": {
               fontSize:"12px"
              }}}>(Director, XLRI Jamshedpur)</Typography>
            </Paper>

            <Paper elevation={3} sx={{p:4, borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px","@media (max-width: 750px)": {
              p:2
              }}}>
                <Avatar alt="Fr S George, S J" src={require("../../../components/assets/avatar4.jpg")} sx={{height:"150px", width:"150px","@media (max-width: 750px)": {
               height:"100px", width:"100px"
              }}}/>
                <Typography textAlign={"center"} sx={{  mb:1, fontSize:"18px", mt:"20px","@media (max-width: 750px)": {
               fontSize:"14px"
              }}}>Prof. Sajeev Abraham George</Typography>
                <Typography textAlign={"center"} sx={{fontSize:"14px", color:"#8d8d8d","@media (max-width: 750px)": {
               fontSize:"12px"
              }}}>(SPJIMR, Mumbai)</Typography>
            </Paper>

            <Paper elevation={3} sx={{p:4, borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px","@media (max-width: 750px)": {
              p:2
              }}}>
                <Avatar alt="Fr S George, S J" src={require("../../../components/assets/avatar5.jpg")} sx={{height:"150px", width:"150px","@media (max-width: 750px)": {
               height:"100px", width:"100px"
              }}}/>
                <Typography textAlign={"center"} sx={{ mb:1, fontSize:"18px", mt:"20px","@media (max-width: 750px)": {
               fontSize:"14px"
              }}}>Prof. Karuna Jain</Typography>
                <Typography textAlign={"center"} sx={{fontSize:"14px", color:"#8d8d8d","@media (max-width: 750px)": {
               fontSize:"12px"
              }}}>(IIT, Bombay)</Typography>
            </Paper>

            <Paper elevation={3} sx={{p:4, borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px","@media (max-width: 750px)": {
              p:2
              }}}>
                <Avatar alt="Fr S George, S J" src={require("../../../components/assets/avatar6.jpg")} sx={{height:"150px", width:"150px","@media (max-width: 750px)": {
               height:"100px", width:"100px"
              }}}/>
                <Typography textAlign={"center"} sx={{mb:1, fontSize:"18px", mt:"20px","@media (max-width: 750px)": {
               fontSize:"14px"
              }}}>Prof. Sushil Gupta</Typography>
                <Typography textAlign={"center"} sx={{fontSize:"14px", color:"#8d8d8d","@media (max-width: 750px)": {
               fontSize:"12px"
              }}}>(Florida International University, USA)</Typography>
            </Paper>

            <Paper elevation={3} sx={{p:4, borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", boxShadow: "rgba(0, 0, 0, 0.25) 0px 15px 25px","@media (max-width: 750px)": {
              p:2
              }}}>
                <Avatar alt="Fr S George, S J" src={require("../../../components/assets/avatar7.jpg")} sx={{height:"150px", width:"150px","@media (max-width: 750px)": {
               height:"100px", width:"100px"
              }}}/>
                <Typography textAlign={"center"} sx={{ mb:1, fontSize:"18px", mt:"20px","@media (max-width: 750px)": {
               fontSize:"14px"
              }}}>Prof. Nagesh N. Murthy</Typography>
                <Typography textAlign={"center"} sx={{fontSize:"14px", color:"#8d8d8d","@media (max-width: 750px)": {
               fontSize:"12px"
              }}}>(University of Oregon, USA)</Typography>
            </Paper>
          </Box>
        </Container>
        </Box>
    </>
  )
}
