import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { exportToExcel  } from "react-json-to-excel";

import {
    Container,
    Paper,
    IconButton,
    Button,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    CircularProgress,
    Snackbar,
  } from "@mui/material";
  import OpenInNewIcon from '@mui/icons-material/OpenInNew';
  import EmailIcon from '@mui/icons-material/Email';
  import DeleteIcon from '@mui/icons-material/Delete';
  import LoginDialogBox from "../../components/loginDialogBox";
  import EmailDialogBox from "../../components/emailDialogBox";
  import MuiAlert from "@mui/material/Alert";

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function Admin() {

    let navigate = useNavigate()

    const params  = useParams()

    const [open, setOpen] = useState(false)
    const [openEmail, setOpenEmail] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState([])
    const [exportLoading, setExportLoading] = useState(false)

    const [alert, setAlert] = useState(false)

    const [users, setUser] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setAlert(false);
    };

    const [isUpdateLoading, setIsUpdateLoading] = useState(false)

    const fetchPayment = async (e) => {
      try {
        const response = await axios.get("/api/v1/payment/allIndianPayments");
        setPaymentStatus(response.data?.payments);
      } catch (error) {
        console.log(error);
      }
    };

    const updatePayment = async (e) => {
      try {
        setIsUpdateLoading(true)
        const response = await axios.get("/api/v1/payment/updateStatus");
        alert(response.data?.message)
      } catch (error) {
        console.log(error);
      } finally{
        fetchPayment()
        setIsUpdateLoading(false)
      }
    };

    const fetchPaper = async (e) => {
        try {
            setIsLoading(true)
            const response = await axios.get(`/api/v1/papers/${params?.limit || 10}`);
            setUser(response.data?.users)
            setIsLoading(false)
        } catch (error) {
            console.log(error?.response?.data?.message)
            setIsLoading(false)
        }
      };

      const checkAuth = (e) => {
        if(localStorage.getItem("adminAuth")!=="authenticate"){
            setOpen(true)
        }
      };

      const logoutHandler = (e) => {
        localStorage.setItem("adminAuth", "");
        setOpen(true)
      };

      const loadMore = (e) => {
        // setLimit(limit+20)
        let currLimit = params?.limit || 10
        navigate(`/somewhere/in/www/admin/${parseInt(currLimit)+20}`)
      };

      const deleleUser = async (id) => {
          try {
            if (window.confirm("Do you want to delete this user?")) {
              const response = await axios.delete(`/api/v1/user/delete/${id}`)
              setAlert(true);
              fetchPaper()
           }
          } catch (error) {
            console.log(error)
          }
      }

      const checkPayment = (email) => {
        for(let i = 0; i<paymentStatus.length; i++){
          if(email === paymentStatus[i].email){
            return "success"
          }
        }
        return "-"
      }

  const exportAuthors = async () => {
        setExportLoading(true)
        try {
          const response = await axios.get("/api/v1/authors")
          exportToExcel(response.data?.authors, 'authors')
        } catch (error) {
          console.log(error)
        } finally {
          setExportLoading(false)
        }
      }

      useEffect(() => {
        checkAuth()
       }, [])
      
      useEffect(() => {
        fetchPaper()
       }, [params?.limit])

       useEffect(() => {
        fetchPayment()
      }, []);


  return (
    <>
      <LoginDialogBox open={open} setOpen={setOpen}/>
      <EmailDialogBox open={openEmail} setOpen={setOpenEmail}/>
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          User Deleted Successfully
        </Alert>
      </Snackbar>
       <Container
        maxWidth="xl"
        sx={{
          py: "70px",
          "@media (max-width: 450px)": {
            py: "30px",
          },
        }}
      >
        <Box sx={{display:"flex", justifyContent:"space-between",mt:"20px","@media (max-width: 1405px)": {
            mt: "30px",
          },}}>
            <Stack spacing={2} direction="row">
            <Button onClick={()=>navigate("/somewhere/in/www/accomodation")}>Accomodation Details</Button>
             <Button onClick={()=>navigate("/somewhere/in/www/payments")}>payments Details</Button>
             <Button disabled={exportLoading} onClick={exportAuthors} endIcon={<OpenInNewIcon />}>Export Emails</Button>
            </Stack>
             <Stack spacing={2} direction="row">
            <Button
            onClick={updatePayment}
            endIcon={isUpdateLoading?<CircularProgress sx={{color:"white"}} />:""}
            disabled={isUpdateLoading}
            loadingPosition="end"
            variant="contained"
          >
            update
          </Button>
          <Button variant="contained" onClick={logoutHandler}>
            Logout
          </Button>
          </Stack>
        </Box>
    <Typography textAlign={"center"} sx={{fontSize:"24px", fontWeight:"600", my:"40px", color:"#2d2d2d"}}>ALL PAPERS</Typography>
    {isLoading  ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height:"100vh"
            }}
          >
            <CircularProgress />
          </Box>
        ) : 
        <>
            <TableContainer sx={styles.table} component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead sx={{ background: "#D3D3D3" }}>
                  <TableRow>
                    <TableCell sx={styles.headTxt}>S.No.</TableCell>
                    <TableCell sx={styles.headTxt} align="right">App. No.</TableCell>
                    <TableCell sx={styles.headTxt} align="right">Name</TableCell>
                    <TableCell sx={styles.headTxt} align="right">
                      Email
                    </TableCell>
                    <TableCell sx={styles.headTxt} align="right">
                      Contact No.
                    </TableCell>
                    <TableCell sx={styles.headTxt} align="right">
                     Payment Status
                    </TableCell>
                    <TableCell sx={styles.headTxt} align="right">
                      Actions
                    </TableCell>
                    <TableCell sx={styles.headTxt} align="right">
                      E-Mail
                    </TableCell>
                    <TableCell sx={styles.headTxt} align="right">
                      More
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((row, i) => (
                    <TableRow
                      key={row?._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={styles.bodyTxt} component="th" scope="row">
                        {i + 1}.
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                        {row?.appNo}
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                        {row?.name}
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                      {row?.email}
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                        {row?.phone}
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                      {
                        checkPayment(row?.email)
                      }
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                      {row?.papers.length === 0?<Typography sx={{fontWeight:600,color:"#6d6d6d"}}>No Submission</Typography>:
                        <Button onClick={() => navigate(`/paper-details/${row?.papers[0]?._id}`)}>View</Button>}
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                      <IconButton aria-label="delete" color="primary" onClick={()=>setOpenEmail(row?.email)}>
                        <EmailIcon />
                      </IconButton>
                      </TableCell>
                      <TableCell sx={styles.bodyTxt} align="right">
                        <Button onClick={()=>deleleUser(row?._id)} variant="contained" color="error" startIcon={<DeleteIcon />}>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={styles.MobileBox}>
              {users?.map((row, i) => (
                <Paper elevation={3} sx={styles.mobileCard}>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={styles.txt1}>S.No.</Typography>
                    <Typography sx={styles.txt2}>{i + 1}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={styles.txt1}>App.No.</Typography>
                    <Typography sx={styles.txt2}>{row?.appNo}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={styles.txt1}>Name</Typography>
                    <Typography sx={styles.txt2}>{row?.name}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={styles.txt1}>Email</Typography>
                    <Typography sx={styles.txt2}>
                    {row?.email}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={styles.txt1}>Contact</Typography>
                    <Typography sx={styles.txt2}>{row?.phone}</Typography>
                  </Stack>
                  {row?.papers.length === 0?<Typography sx={{fontWeight:600,color:"#6d6d6d"}}>No Submission</Typography>:
                        <Button sx={{mt:1}} onClick={() => navigate(`/paper-details/${row?.papers[0]?._id}`)}>View</Button>}
                </Paper>
              ))}
            </Box>
            </>
}
<Box sx={{display:"flex", justifyContent:"center",mt:"20px"}}>
  <Button variant="contained" onClick={loadMore}>Load More</Button>
</Box>
    </Container> 
    </>
  )
}

const styles = {
    headTxt: {
      fontSize: "16px",
      fontWeight: "600",
    },
    bodyTxt: {
      fontSize: "15px",
      wordWrap: "break-word",
      maxWidth: "200px",
    },
    table: {
      "@media (max-width: 1000px)": {
        display: "none",
      },
    },
    MobileBox: {
      "@media (min-width: 1000px)": {
        display: "none",
      },
    },
    mobileCard: {
      p: 2,
      my: 1,
    },
    txt1: {
      fontWeight: "600",
      minWidth: "120px",
    },
    txt2: {
      wordWrap: "break-word",
      maxWidth: "180px",
    },
  };